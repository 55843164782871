import React from "react";
import { useNavigate } from "react-router-dom";
import { SimpleSearchParams } from "../../../hooks/SearchParams";

const NavButtons = ({
  icon = "",
  LinkTo = "",
  Title = "",
  onClick = () => {},
  visible = true,
}) => {
  const store = SimpleSearchParams().get("store");
  const Navigate = useNavigate();

  const PersistentParams = () => {
    if (store && store !== "null") {
      return `?store=${store}`;
    } else {
      return "";
    }
  };

  const handleClick = () => {
    onClick();
    Navigate(LinkTo + PersistentParams());
  };
  if (!visible) return null;
  return (
    <div className="NavButton" onClick={handleClick}>
      <div className="icon">
        <img src={icon} alt="logo" />
      </div>
      <div>{Title}</div>
    </div>
  );
};

export default NavButtons;
