import React from "react";
import SideBar from "../../components/SideBar/SideBar";
import "./styles.css";
import { Outlet } from "react-router-dom";
const MainPage = () => {
  return (
    <main id="MainPage">
      <SideBar />
      <Outlet />
    </main>
  );
};

export default MainPage;
