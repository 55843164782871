import React, { FormEvent, useState } from "react";
import InputComponent from "../../../components/Input/InputComponent";
import { UserType } from "../../../types/User";
import { PhoneNumber } from "../../../services/Mask";
import { PostApi } from "../../../services/Api";
import { UseFetch } from "../../../hooks/useFetch";
import { GroupType } from "../../../types/Groups";
import { SimpleSearchParams } from "../../../hooks/SearchParams";

const CreateStore = () => {
  const SearchParams = SimpleSearchParams();
  let [newUser, setNewUser] = useState<UserType>({
    name: "",
    phoneNumber: "",
    email: "",
    storeName: "",
    groupId: "",
  });
  let [createLoading, setCreateLoading] = useState<boolean>(false);
  let [errorMessage, setErrorMessage] = useState<string>(" ");
  let [aceptMessage, setAceptMessage] = useState<string>(" ");
  let { data: groups } = UseFetch<GroupType[]>("/groups");

  const handleChange = (element: FormEvent<HTMLInputElement>) => {
    let { id, value } = element.currentTarget;

    setNewUser({ ...newUser, [id]: value });
  };

  const handleSubmit = (element: FormEvent<HTMLFormElement>) => {
    element.preventDefault();
    if (createLoading) return;
    setCreateLoading(true);
    setErrorMessage("");
    setAceptMessage("");
    let { email, name, phoneNumber, storeName, groupId } = newUser;
    PostApi<{ message?: string }>("/users", {
      email,
      name,
      phoneNumber: "+55" + phoneNumber.replace(/\D/g, ""),
      storeName,
      groupId,
    })
      .then(({ status, data }) => {
        if (status === 201) {
          clearData();
          setAceptMessage("Loja Adicionada");
        } else {
          if (!data.message) return;
          if (data.message === "User Already Exists")
            setErrorMessage("Usuário já existe");
          else setErrorMessage("erro desconhecido");
        }
      })
      .catch(() => setErrorMessage("Ocorreu algum erro"))
      .finally(() => setCreateLoading(false));
  };
  const clearData = () => {
    setNewUser({
      name: "",
      phoneNumber: "",
      email: "",
      storeName: "",
      groupId: "",
    });
  };
  return (
    <section id="CreateStore">
      <header>
        <h1>Adicionar Loja</h1>
        <h3>Preencha as informações abaixo para adicionar uma loja</h3>
      </header>
      <main>
        <h2 style={{ color: "red" }}>{errorMessage}</h2>
        <form onSubmit={handleSubmit}>
          <InputComponent
            value={newUser["storeName"]}
            onChange={handleChange}
            id="storeName"
            label="Nome da loja:"
            required
          />
          <InputComponent
            value={newUser["name"]}
            onChange={handleChange}
            id="name"
            label="Nome do responsavel:"
            required
          />
          <div id="groupId">
            <label htmlFor="groupId">Grupo:</label>
            <select
              name="groupId"
              id="groupId"
              required
              onChange={(e) =>
                setNewUser({ ...newUser, groupId: e.target.value })
              }
            >
              {groups &&
                groups.map((group) => (
                  <option value={group.groupId}>{group.name}</option>
                ))}
            </select>
          </div>
          <InputComponent
            value={PhoneNumber(newUser["phoneNumber"])}
            onChange={handleChange}
            id="phoneNumber"
            maxlength="16"
            label="Telefone:"
            type="tel"
            required
          />
          <InputComponent
            value={newUser["email"]}
            onChange={handleChange}
            id="email"
            label="E-mail:"
            type="email"
            required
          />
          <div id="buttons">
            <h2 style={{ color: "#2ca706" }}>{aceptMessage}</h2>
            <button
              type="reset"
              className="second"
              onClick={() => SearchParams.delete("modal")}
            >
              Esquecer
            </button>
            <button type="submit" disabled={createLoading}>
              Salvar
            </button>
          </div>
        </form>
      </main>
    </section>
  );
};

export default CreateStore;
