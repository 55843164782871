import React from "react";
import { ProductType } from "../../../types/Products";
import { BRL } from "../../../services/Mask";
import Modal from "../../../components/Modal/Modal";
import EditProduct from "./EditProduct";

interface ProductComponentType {
  Product: ProductType;
  handleClick: () => void;
  updateProducts?: () => void;
}
const Product = ({
  Product,
  handleClick,
  updateProducts,
}: ProductComponentType) => {
  return (
    <>
      <Modal
        modalName={Product.productId}
        modalLabel="editProduct"
        onClose={updateProducts}
      >
        <EditProduct product_default={Product} />
      </Modal>
      <div className="product" onClick={handleClick}>
        <div className="product-img">
          <img src={Product.imageUrl} alt={Product.name} />
        </div>
        <div className="product-info">
          <h2>{Product.name}</h2>
          <p>{BRL((Product?.acquisitionValue || 0).toFixed(2))}</p>
        </div>
      </div>
    </>
  );
};

export default Product;
