import React, { useEffect, useState } from "react";
import Filters from "./Filters";
import { UseFetch } from "../../../hooks/useFetch";
import { invoicingDayByDayType } from "../../../types/Sales";
import { useSearchParams } from "react-router-dom";
import { MonthRange } from "../../../services/Utils";
import AreaChartComponet from "../../../components/Charts/AreaChartComponet";
import { DefaultDate, LocaleDate } from "../../../services/Mask";

const Graphic = () => {
  const { innerWidth: width } = window;
  let currentDate = new Date();
  let currentMonth = MonthRange({});
  const [SearchParams, setSeatchParams] = useSearchParams({
    startDate: DefaultDate(
      width >= 700
        ? currentMonth.startDate
        : new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDay() - 7
          )
    ),
    endDate: DefaultDate(width >= 700 ? currentMonth.endDate : currentDate),
  });
  const [Series, setSeries] = useState<
    {
      name: string;
      data: (number | null)[] | null[];
      color: string;
    }[]
  >([]);
  const [categories, setCategories] = useState<string[] | number[]>([]);
  const startDate = SearchParams.get("startDate");
  const endDate = SearchParams.get("endDate");
  const storeId = SearchParams.get("store");
  const {
    data: invoicingData,
    update: updateInvoicing,
    loading: loadingInvoicing,
  } = UseFetch<invoicingDayByDayType[]>("/sales/invoicing/day-by-day", {
    params: {
      startDate,
      endDate,
      storeId,
    },
  });
  useEffect(() => {
    if (loadingInvoicing) return;
    updateInvoicing();
  }, [startDate, endDate, storeId]);
  useEffect(() => {
    if (!invoicingData) return;
    setSeries([
      {
        name: "Lucro",
        color: "#504BE9",
        data: invoicingData.map((day) => day.invoicing || 0),
      },
    ]);
    setCategories(
      invoicingData.map((day) => {
        let formated = LocaleDate(day.date).split("/");
        return `${formated[0]}`;
      })
    );
  }, [invoicingData]);
  return (
    <section id="graphic">
      <h3>Ganhos</h3>
      <Filters />
      <div id="container">
        <AreaChartComponet
          series={Series}
          xaxis={{ categories }}
          height="99%"
          width="99%"
        />
      </div>
    </section>
  );
};

export default Graphic;
