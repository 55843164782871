import React, { useEffect, useState } from "react";
import HeaderComponent from "../../../components/Header/HeaderComponent";
import { GroupType } from "../../../types/Groups";
import GroupMembers from "./GroupMembers";
import FilterComponent from "../../../components/Filter/FilterComponent";
import { useParams } from "react-router-dom";
import { UseFetch } from "../../../hooks/useFetch";
import Modal from "../../../components/Modal/Modal";
import { SimpleSearchParams } from "../../../hooks/SearchParams";
import EditGroup from "./EditGroup";
import GroupCatalog from "./GroupCatalog";
import AddProduct from "./SelectProduct";

const GroupDetals = () => {
  let { id } = useParams();
  let { data: group, update } = UseFetch<GroupType>(`/groups/${id}`);
  const SearchParams = SimpleSearchParams();

  let [permissions, setPermissions] = useState<string[]>([]);

  useEffect(() => {
    if (!group) return;
    setPermissions(
      group.permissions.map((permission) => permission.permissionId)
    );
  }, [group]);
  return (
    <>
      <Modal modalName="editGroup" onClose={update} background={false}>
        <EditGroup
          name={group?.name !== undefined ? group.name : ""}
          description={
            group?.description !== undefined ? group.description : ""
          }
          permissions={permissions}
          setPermissions={setPermissions}
          currentHasCommission={group?.hasCommission || false}
          currentDefaultProducts={group?.useDefaultProducts}
        />
      </Modal>
      {console.log(group?.useDefaultProducts)}
      <Modal modalName="catalog">
        <GroupCatalog />
      </Modal>
      <Modal
        modalName="addProduct"
        close={() => SearchParams.set("modal", "catalog")}
      >
        <AddProduct />
      </Modal>

      <main id="groupdetails">
        <HeaderComponent title={group?.name || ""} lead={group?.description} />
        <FilterComponent
          buttons={[
            {
              label: "Catálogo",
              action: () => SearchParams.set("modal", "catalog"),
              forAdmin: true,
            },
            {
              label: "Editar",
              action: () => SearchParams.set("modal", "editGroup"),
              forAdmin: true,
            },
          ]}
        />
        <section id="container">
          <GroupMembers groupId={id || ""} />
        </section>
      </main>
    </>
  );
};

export default GroupDetals;
