import React, { FormEvent, useState } from "react";
import HeaderComponent from "../../../components/Header/HeaderComponent";
import { DeleteApi } from "../../../services/Api";
import LoadingComponent from "../../../components/Loading/LoadingComponent";
import { SimpleSearchParams } from "../../../hooks/SearchParams";
interface deleteInvoicesTypes {
  selectedInvoices: string[];
  updateInvoices: () => void;
}
const DeleteInvoices = ({
  selectedInvoices,
  updateInvoices,
}: deleteInvoicesTypes) => {
  let SearchParams = SimpleSearchParams();
  let [error, setError] = useState(``);
  let [DeleteLoading, setDeleteLoading] = useState(false);
  let [confirmInput, setConfirmInput] = useState(``);
  let closeModal = () => {
    SearchParams.delete(`modal`);
  };

  let handleSubmit = async (element: FormEvent<HTMLFormElement>) => {
    element.preventDefault();
    if (DeleteLoading) return;
    setDeleteLoading(true);
    let response = await DeleteApi(`invoices`, {
      invoiceIds: selectedInvoices,
    }).catch((err) => {
      setError(`Ocorreu um erro`);
      return err;
    });
    setDeleteLoading(false);
    if (response.status === 204) {
      updateInvoices();
      closeModal();
    }
  };
  return (
    <main id="deleteInvoices">
      <HeaderComponent title="Deletar Faturas" />
      <div className="content">
        <p>
          Para exclusão de uma ou mais vendas, favor escrever abaixo a palavra
          CONFIRMO
        </p>
        {error && <p>{error}</p>}
        {DeleteLoading && <LoadingComponent />}
        <form onSubmit={handleSubmit}>
          <input
            id="deleteSalesConfirm"
            value={confirmInput}
            onChange={(e) => setConfirmInput(e.target.value.toUpperCase())}
            placeholder="CONFIRMO"
            autoComplete="off"
          />
          <div id="buttons">
            <button disabled={confirmInput !== "CONFIRMO"} type="submit">
              Excluir
            </button>
          </div>
        </form>
      </div>
    </main>
  );
};

export default DeleteInvoices;
