import React, { useEffect, useState } from "react";
import { UseFetch } from "../../../hooks/useFetch";
import { StoreType } from "../../../types/Stores";
import LoadingComponent from "../../../components/Loading/LoadingComponent";
interface selectedStoresTypes {
  selectedStores: string[];
  setSelectedStores: (stores: string[]) => void;
}
const SelectStores = ({
  selectedStores,
  setSelectedStores,
}: selectedStoresTypes) => {
  let [filter, setFilter] = useState(``);
  let { data: StoresData, loading: StoresLoading } =
    UseFetch<StoreType[]>("/stores/all");

  const addSelectedStore = (storeId: string) => {
    let isSelected = selectedStores.find((store) => store === storeId);
    if (isSelected) return;
    setSelectedStores([...selectedStores, storeId]);
  };
  const removeSelectedStore = (storeId: string) => {
    let isSelected = selectedStores.find((store) => store === storeId);
    if (!isSelected) return;
    setSelectedStores(selectedStores.filter((store) => store !== storeId));
  };
  useEffect(() => {
    if (!StoresData || StoresLoading) return;
    let selectStores = StoresData.filter((store) => {
      if (store.isFactory || store.disabled) return false;
      return true;
    }).map((store) => {
      return store.storeId;
    });

    if (selectStores) {
      setSelectedStores(selectStores);
    }
  }, [StoresData]);
  return (
    <main id="selectStores">
      <header>
        <input
          type="search"
          placeholder="Filtrar"
          value={filter}
          onChange={(Element) =>
            setFilter(Element.currentTarget.value.toLowerCase())
          }
        />
      </header>
      <section id="stores-container">
        {!StoresLoading ? (
          StoresData?.map((store, index) => {
            if (store.isFactory || store.disabled || store.cashPayment)
              return null;
            if (!store.name.toLowerCase().includes(filter) && filter !== ``)
              return null;
            let isSelected = !!selectedStores.find(
              (storeId) => storeId === store.storeId
            );
            return (
              <StoreComponent
                key={index}
                isSelected={isSelected}
                addSelectedStore={addSelectedStore}
                removeSelectedStore={removeSelectedStore}
                store={store}
              />
            );
          })
        ) : (
          <LoadingComponent />
        )}
      </section>
    </main>
  );
};

export default SelectStores;

const StoreComponent = ({
  store,
  removeSelectedStore,
  addSelectedStore,
  isSelected,
}: {
  store: StoreType;
  removeSelectedStore: (storeId: string) => void;
  addSelectedStore: (storeId: string) => void;
  isSelected: boolean;
}) => {
  const handleClick = () => {
    if (isSelected) {
      removeSelectedStore(store.storeId);
    } else {
      addSelectedStore(store.storeId);
    }
  };
  return (
    <div
      onClick={handleClick}
      className={`store ${isSelected ? "selected" : ""}`}
    >
      {store.name}
    </div>
  );
};
