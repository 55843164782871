import React from "react";
import { dayType } from "../../../types/Calendar";

const DayComponent = ({
  day,
  setselectedDay,
}: {
  day: dayType;
  setselectedDay: (day: dayType) => void;
}) => {
  return (
    <div
      className={`day ${day.now ? "now" : ""} ${
        day.currentMonth ? "currentMonth" : ""
      } ${day.selected ? "selected" : ""}`}
      onClick={() => setselectedDay(day)}
    >
      {day.day}
    </div>
  );
};

export default DayComponent;
