import React, { useEffect, useState } from "react";
import { UseFetch } from "../../../hooks/useFetch";
import { BRL } from "../../../services/Mask";
import { SimpleSearchParams } from "../../../hooks/SearchParams";

const Invoicing = () => {
  const SearchParams = SimpleSearchParams({ storeId: "" });

  const storeId = SearchParams.get("store");

  const [currentInvoicing, setCurrentInvoicing] = useState(0);
  const { data, update } = UseFetch<{ invoicing: number }>("/sales/invoicing", {
    params: { storeId },
  });

  useEffect(() => {
    if (!data) return;
    setCurrentInvoicing(data.invoicing);
  }, [data]);

  useEffect(() => {
    update();
  }, [storeId]);
  return (
    <section id="invoncing">
      <div className="invoicing-info">
        <div>
          <h3>Faturamento Total:</h3>
        </div>
        <div>
          <h1>{BRL(currentInvoicing.toFixed(2))}</h1>
        </div>
      </div>
      <div className="invoicing-graph-container">
        <div className="invoicing-graph invoicing-graph-1" />
        <div className="invoicing-graph invoicing-graph-2" />
        <div className="invoicing-graph invoicing-graph-3" />
        <div className="invoicing-graph invoicing-graph-4" />
        <div className="invoicing-graph invoicing-graph-5" />
      </div>
    </section>
  );
};

export default Invoicing;
