import React, { ChangeEvent } from "react";
import { SimpleSearchParams } from "../../hooks/SearchParams";
import "./styles.css";
import DayByDay from "./components/DayByDay";
import { IsAdministrator } from "../../services/Utils";
import useLocalStorage from "../../hooks/useLocalStorege";
import gridIcon from "../../assets/icons/grid.svg";
import listIcon from "../../assets/icons/list.svg";
type statusType = { label: string; value: string; disabled?: boolean };
type buttonType = {
  className?: string;
  label: string;
  action: () => void;
  forAdmin?: boolean;
  forUser?: boolean;
  icon?: string;
  hidden?: boolean;
  disabled?: boolean;
};

interface FiltersType {
  buttons?: buttonType[];
  status?: statusType[];
  dateFilter?: "week" | "month" | "dayByDay";
  viewName?: string;
  perPage?: boolean;
}

const FilterComponent = ({
  buttons,
  status,
  dateFilter,
  perPage,
  viewName,
}: FiltersType) => {
  let [viewStyle, setViewStyleLocal] = useLocalStorage(viewName || "", "grid");

  const SearchParams = SimpleSearchParams({ perPage: "10" });
  let perPageValue = SearchParams.get("perPage") as string;
  const handleChangeStatus = (element: ChangeEvent<HTMLSelectElement>) => {
    let { value } = element.target;
    SearchParams.set("status", value);
  };
  const handleChangePerPage = (element: ChangeEvent<HTMLSelectElement>) => {
    let { value } = element.target;
    SearchParams.set("perPage", value);
  };

  let setViewStyle = (style: string) => {
    setViewStyleLocal(style);
    SearchParams.set("viewStyle", style);
  };

  return (
    <section id="FilterComponent">
      <div className="containers">
        {perPage && (
          <div className="per-page">
            Por Página:{" "}
            <select onChange={handleChangePerPage} value={perPageValue}>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="100">100</option>
            </select>
          </div>
        )}
        <div className="date-filter">
          {dateFilter === "dayByDay" && <DayByDay />}
        </div>
      </div>
      <div className="containers">
        {buttons && (
          <div className="buttons">
            {buttons.map((option, index) => {
              if (option.hidden) return;
              let isAdmin = localStorage.getItem("isAdmin") === "true";
              if (
                (IsAdministrator() && option.forAdmin) ||
                (!isAdmin && option.forUser)
              )
                return (
                  <button
                    className={option.className}
                    key={index}
                    onClick={option.action}
                    disabled={option.disabled || false}
                  >
                    {option.icon ? <img src={option.icon} alt="" /> : ""}
                    {option.label}
                  </button>
                );
            })}
          </div>
        )}
        {status && (
          <div className="status">
            <select onChange={handleChangeStatus}>
              {status.map((statusOption, index) => {
                return (
                  <option
                    key={index}
                    value={statusOption.value}
                    disabled={statusOption.disabled}
                  >
                    {statusOption.label}
                  </option>
                );
              })}
            </select>
          </div>
        )}
        {viewName && (
          <div className="viewTipe">
            <div
              id="list"
              className={viewStyle === "list" ? "selected" : ""}
              onClick={() => setViewStyle("list")}
            >
              <img src={listIcon} alt="list" />
            </div>
            <div
              id="grid"
              className={viewStyle === "grid" ? "selected" : ""}
              onClick={() => setViewStyle("grid")}
            >
              <img src={gridIcon} alt="grid" />
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default FilterComponent;
