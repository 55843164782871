import React, { MouseEventHandler } from "react";
import { InvoiceType } from "../../../types/Invoice";
import { BRL, LocaleDate } from "../../../services/Mask";
import InvoiceIcon from "../../../assets/icons/note.svg";
import { IsAdministrator } from "../../../services/Utils";
import { PutApi } from "../../../services/Api";
import Modal from "../../../components/Modal/Modal";
import InvoiceDetail from "./InvoiceDetail";
import { SimpleSearchParams } from "../../../hooks/SearchParams";

interface InvoiceComponentType {
  invoice: InvoiceType;
  selectedInvoicing: string[];
  setSelectedInvoicing: (value: string[]) => void;
  updateInvoices: () => void;
}

const Invoice = ({
  invoice,
  selectedInvoicing,
  setSelectedInvoicing,
  updateInvoices,
}: InvoiceComponentType) => {
  let SearchParams = SimpleSearchParams();
  const isSelected = () =>
    !!selectedInvoicing.find(
      (currentInvoicing) => currentInvoicing === invoice.invoiceId
    );
  const handleSelect = () => {
    if (isSelected()) {
      setSelectedInvoicing(
        selectedInvoicing.filter(
          (currentInvoice) => currentInvoice !== invoice.invoiceId
        )
      );
    } else {
      setSelectedInvoicing([...selectedInvoicing, invoice.invoiceId]);
    }
  };
  const handleChangeStatus = async () => {
    let response = await PutApi(`invoices/${invoice.invoiceId}/paid`, {});

    if (response.status === 204) {
      updateInvoices();
    }
  };
  const handleClick = () => {
    SearchParams.set("modal", invoice.invoiceId);
  };
  return (
    <>
      <Modal modalName={invoice.invoiceId}>
        <InvoiceDetail invoice={invoice} />
      </Modal>
      <section className="invoice-item">
        <div className="invoice-checkbox">
          <input
            type="checkbox"
            checked={isSelected()}
            onChange={handleSelect}
            disabled={invoice.status === `paid`}
          />
        </div>
        <div className="invoice-img" onClick={handleClick}>
          <img src={InvoiceIcon} alt="Invoice icon" />
        </div>
        <div className="invoice-title" onClick={handleClick}>
          <div>{IsAdministrator() ? invoice.invoiceStore.name : "Fatura"}</div>
          <div>
            {LocaleDate(invoice.startDate)} - {LocaleDate(invoice.endDate)}
          </div>
        </div>
        <div className="invoice-select" onClick={handleClick}>
          {LocaleDate(invoice.createdAt)}
        </div>
        <div className="invoice-amount" onClick={handleClick}>
          {BRL(invoice.amount.toFixed(2))}
        </div>
        <div className="invoice-amount-mobile" onClick={handleClick}>
          {invoice.amount.toFixed(2)}
        </div>
        <div className="invoice-status">
          <select
            className={`status-select ${invoice.status}`}
            disabled={invoice.status !== "pending" || !IsAdministrator()}
            onChange={handleChangeStatus}
            value={invoice.status}
          >
            <option className="paid" value="paid">
              Pago
            </option>
            <option value="pending" disabled>
              Pendente
            </option>
          </select>
        </div>
      </section>
    </>
  );
};

export default Invoice;
