import React, { useEffect } from "react";
import { ProductsRankingType } from "../../../types/Products";
import { UseFetch } from "../../../hooks/useFetch";
import { SimpleSearchParams } from "../../../hooks/SearchParams";

const Products = () => {
  const SearchParams = SimpleSearchParams({ store: "" });
  let storeId = SearchParams.get("store");
  const { data: ProducsData, update: UpdateProducts } = UseFetch<
    ProductsRankingType[]
  >("products/ranking", { params: { perPage: 3, storeId } });
  useEffect(UpdateProducts, [storeId]);
  return (
    <section id="products">
      <header>
        <h3>Top Produtos</h3>
      </header>
      <section id="container">
        {!ProducsData?.length ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "4rem",
            }}
          >
            <h3>Sem vendas</h3>
          </div>
        ) : (
          ProducsData.map((Product, index) => {
            if (index > 2) return;
            return (
              <div className="product" key={index}>
                <div>
                  <img src={Product.imageUrl} alt={Product.name} />
                </div>
                <div>
                  <p>{Product.name}</p>
                </div>
                <div>
                  <p>{Product.count}</p>
                </div>
              </div>
            );
          })
        )}
      </section>
    </section>
  );
};

export default Products;
