import React, { FormEvent, useState } from "react";
import HeaderComponent from "../../../components/Header/HeaderComponent";
import InputComponent from "../../../components/Input/InputComponent";
import { PostApi } from "../../../services/Api";
import { SimpleSearchParams } from "../../../hooks/SearchParams";

const ResetPassword = () => {
  const SearchParams = SimpleSearchParams();
  let [message, setMessage] = useState(
    "Um código de recuperação foi enviado para o seu email"
  );
  let [resetCode, setResetCode] = useState("");
  let [password, setPassword] = useState("");
  let [passwordConfirm, setPasswordConfirm] = useState("");
  let email = SearchParams.get("email");

  const handleSubmit = (element: FormEvent<HTMLFormElement>) => {
    element.preventDefault();

    if (!resetCode || !password || !passwordConfirm || !email) {
      return setMessage("Existem parametros faltando");
    }
    if (password !== passwordConfirm) {
      return setMessage("As senhas devem ser iguais");
    }

    PostApi<{ message: string }>("auth/reset-forgot-password", {
      email,
      password,
      confirmationCode: resetCode,
    })
      .then(({ status, data }) => {
        if (status === 200) {
          return setMessage("Senha redefinida com sucesso");
        } else if (status === 400) {
          if (data.message === "Incorrect Confirmation Code") {
            setMessage("Código de confirmação incorreto");
          } else if (data.message === "Confirmation Code Expired") {
            setMessage("Código de confirmação expirado");
          }
        } else {
          setMessage("Erro desconhecido");
        }
      })
      .catch(() => {
        setMessage("Erro desconhecido");
      });
  };

  return (
    <section id="resetPassword">
      <HeaderComponent title="Redefinição de senha" />
      <section id="container">
        <h2>{message}</h2>
        <form onSubmit={handleSubmit}>
          <InputComponent
            id="code"
            label="Codigo de recuperação"
            value={resetCode}
            minLength={6}
            onChange={(element: FormEvent<HTMLInputElement>) => {
              setResetCode(element.currentTarget.value);
            }}
            required
          />
          <InputComponent
            id="password"
            label="Senha"
            type="password"
            value={password}
            minLength={8}
            onChange={(element: FormEvent<HTMLInputElement>) => {
              setPassword(element.currentTarget.value);
            }}
            required
          />
          <InputComponent
            id="passwordConfirm"
            label="Confirmação de Senha"
            type="password"
            minLength={8}
            value={passwordConfirm}
            onChange={(element: FormEvent<HTMLInputElement>) => {
              setPasswordConfirm(element.currentTarget.value);
            }}
            required
          />
          <div className="buttons">
            <button type="submit">Enviar</button>
          </div>
        </form>
      </section>
    </section>
  );
};

export default ResetPassword;
