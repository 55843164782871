import { DateRangeType } from "../types/Utils";

import IronIcon from "../assets/icons/ferro.svg";
import BronzeIcon from "../assets/icons/BRONZE.svg";
import GoldIcon from "../assets/icons/OURO.svg";
import PlatineIcon from "../assets/icons/platina.svg";
import RubyIcon from "../assets/icons/RUBY.svg";
import SilverIcon from "../assets/icons/PRATA.svg";
import DiamondIcon from "../assets/icons/DIAMANTE.svg";
import EmeraldIcon from "../assets/icons/ESMERALDA.svg";

interface MonthRangeType {
  month?: number;
  year?: number;
}
export const MonthRange = ({ month, year }: MonthRangeType): DateRangeType => {
  let current = new Date();

  let firsthDay = new Date(
    year || current.getFullYear(),
    month ? month - 1 : current.getMonth(),
    1
  );
  let lastDay = new Date(
    firsthDay.getFullYear(),
    firsthDay.getMonth() + 1,
    firsthDay.getDate() - 1
  );

  return {
    startDate: firsthDay,
    endDate: lastDay,
  };
};

export const WeekRange = (date = new Date()): DateRangeType => {
  let current = new Date(date);

  let firsthDay = new Date(
    current.getFullYear(),
    current.getMonth(),
    current.getDate() - 7
  );

  return {
    startDate: firsthDay,
    endDate: current,
  };
};
export const IsAdministrator = () => localStorage.getItem("isAdmin") === "true";
export const getLanding = (
  ranking:
    | "iron"
    | "bronze"
    | "silver"
    | "gold"
    | "platinum"
    | "emerald"
    | "ruby"
    | "diamond"
) => {
  const Landings = {
    iron: {
      name: "Ferro",
      initialValue: 0,
      finalValue: 1000000 / 100,
      icon: IronIcon,
    },
    bronze: {
      name: "Bronze",
      initialValue: 1000000 / 100,
      finalValue: 5000000 / 100,
      icon: BronzeIcon,
    },
    silver: {
      name: "Prata",
      initialValue: 5000000 / 100,
      finalValue: 10000000 / 100,
      icon: SilverIcon,
    },
    gold: {
      name: "Ouro",
      initialValue: 10000000 / 100,
      finalValue: 15000000 / 100,
      icon: GoldIcon,
    },
    platinum: {
      name: "Platina",
      initialValue: 15000000 / 100,
      finalValue: 20000000 / 100,
      icon: PlatineIcon,
    },
    emerald: {
      name: "Esmeralda",
      initialValue: 20000000 / 100,
      finalValue: 30000000 / 100,
      icon: EmeraldIcon,
    },
    ruby: {
      name: "Rubi",
      initialValue: 30000000 / 100,
      finalValue: 40000000 / 100,
      icon: RubyIcon,
    },
    diamond: {
      name: "Diamante",
      initialValue: 40000000 / 100,
      finalValue: 50000000 / 100,
      icon: DiamondIcon,
    },
  };

  return Landings[ranking];
};
