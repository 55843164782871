import React, { FormEvent, useState } from "react";
import InputComponent from "../../../components/Input/InputComponent";

import logo from "../../../assets/teste.svg";
import { PostApi } from "../../../services/Api";
import LoadingComponent from "../../../components/Loading/LoadingComponent";
const SetPassword = () => {
  let [message, setMessage] = useState("");
  let [loading, setLoading] = useState(false);
  let [password, setPassword] = useState("");
  let [confirmPassword, setConfirmPassword] = useState("");

  const handleSubmit = (element: FormEvent) => {
    element.preventDefault();
    setMessage("");
    if (password !== confirmPassword) {
      return setMessage("Confirmação de senha invalida");
    } else if (password === "" && confirmPassword === "") {
      return setMessage("Confirmação de senha invalida");
    }

    if (loading) return;
    setLoading(true);

    PostApi("users/definePssword", {
      email: localStorage.getItem("email"),
      password: password,
      session: localStorage.getItem("session"),
    })
      .then((responde) => {
        setMessage("Senha atualizada");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <section id="setPassword">
      <header>
        <img src={logo} alt="logo" />
        <h1>Crie uma nova senha </h1>
      </header>
      <main>
        <h2 className="error">{message}</h2>
        <LoadingComponent isLoading={loading} />
        <form onSubmit={handleSubmit}>
          <InputComponent
            label="Nova Senha"
            id="password"
            type="password"
            minLength={8}
            value={password}
            onChange={(Element: FormEvent<HTMLInputElement>) => {
              setPassword(Element.currentTarget.value);
            }}
            required
          />
          <InputComponent
            label="Confirmar nova Senha"
            id="password"
            type="password"
            minLength={8}
            value={confirmPassword}
            onChange={(Element: FormEvent<HTMLInputElement>) => {
              setConfirmPassword(Element.currentTarget.value);
            }}
            required
          />
          <div id="buttons">
            <button type="submit">Atualizar</button>
          </div>
        </form>
      </main>
    </section>
  );
};

export default SetPassword;
