import React, { ChangeEvent } from "react";
import { SimpleSearchParams } from "../../../hooks/SearchParams";
import { MonthRange } from "../../../services/Utils";
import { DefaultDate } from "../../../services/Mask";

const DayByDay = () => {
  let currentMonth = MonthRange({});
  const SearchParms = SimpleSearchParams({
    startDate: DefaultDate(currentMonth.startDate),
    endDate: DefaultDate(currentMonth.endDate),
  });

  let startDate = SearchParms.get("startDate") as string;
  let endDate = SearchParms.get("endDate") as string;

  const handleStartDate = (element: ChangeEvent<HTMLInputElement>) => {
    let { value } = element.currentTarget;
    SearchParms.set("startDate", value);
  };
  const handleEndDate = (element: ChangeEvent<HTMLInputElement>) => {
    let { value } = element.currentTarget;
    SearchParms.set("endDate", value);
  };
  return (
    <div className="day-by-day-filter">
      <input
        type="date"
        value={startDate}
        onChange={handleStartDate}
        max={endDate}
      />

      <input
        type="date"
        value={endDate}
        onChange={handleEndDate}
        min={startDate}
      />
    </div>
  );
};

export default DayByDay;
