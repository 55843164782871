import React from "react";

import ExitIcon from "../../../assets/icons/exit.svg";
import { useNavigate } from "react-router-dom";

const OptionsComponent = () => {
  const Navigate = useNavigate();
  const logout = () => {
    localStorage.clear();
    Navigate("/");
  };

  return (
    <section className="options">
      <button className="second" onClick={logout}>
        <img src={ExitIcon} alt="exit" />
        Sair
      </button>
    </section>
  );
};

export default OptionsComponent;
