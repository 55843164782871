import React, { ReactNode, useEffect, useState } from "react";
import "./styles.css";
import closeImg from "../../assets/close.svg";
import { useSearchParams } from "react-router-dom";

type ModalTypes = {
  children: ReactNode;
  modalName: string;
  onClose?: () => void;
  modalLabel?: string;
  background?: boolean;
  close?: () => void;
};

const Modal = ({
  children,
  modalName,
  onClose,
  modalLabel = "modal",
  background = true,
  close,
}: ModalTypes) => {
  const [SearchParams, SetSearchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  let modal = SearchParams.get(modalLabel);
  const handleClose = () => {
    if (close) return close();
    SearchParams.delete(modalLabel);
    SetSearchParams(SearchParams);
  };
  useEffect(() => {
    if (modal === modalName) {
      setIsOpen(true);
    } else {
      if (isOpen && onClose) onClose();
      setIsOpen(false);
    }
  }, [modal]);

  if (!isOpen) return null;
  return (
    <div id="ModalBackground" className={`${background ? "" : "clear"}`}>
      <section id="ModalComponent">
        <span id="close" onClick={handleClose}>
          <img src={closeImg} alt="close" />
        </span>
        {children}
      </section>
    </div>
  );
};

export default Modal;
