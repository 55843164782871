import React from "react";
import DayComponent from "./DayComponent";
import { dayType } from "../../../types/Calendar";

interface WeekComponentType {
  week: dayType[];
  setselectedDay: (day: dayType) => void;
}

const WeekComponent = ({ week, setselectedDay }: WeekComponentType) => {
  return (
    <section className="week">
      {week.map((day) => (
        <DayComponent
          key={`${day.day}/${day.month}`}
          day={day}
          setselectedDay={setselectedDay}
        />
      ))}
    </section>
  );
};

export default WeekComponent;
