export const BRL = (value?: string | number) => {
  if (!value) return `R$ -`;
  const isNegative = Number(value) < 0;
  value = `${value}`;
  value = value.replace(/\D/g, ""); // Remove todos os não dígitos
  value = value.replace(/(\d+)(\d{2})$/, "$1,$2"); // Adiciona a parte de centavos
  value = value.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."); // Adiciona pontos a cada três dígitos
  value = isNegative ? "R$ -" + value : "R$ " + value;
  return value;
};
export const LocaleDate = (value: string | Date) => {
  return new Date(value).toLocaleDateString("pt-BR", { timeZone: "UTC" });
};
export const PhoneNumber = (value: string) => {
  value = value.replace(/55/, '')
  value = value.replace(/\D/g, "")
  value = value.length === 11 ?
    value.replace(/(\d{2})(\d{1})(\d{4})(\d{4})$/, '($1) $2 $3-$4') :
    value.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3')
  return value;
};
export const DefaultDate = (value: Date) => {
  let month = value.getMonth() + 1;
  let day = value.getDate();
  return `${value.getFullYear()}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day
    }`;
};
