import { createBrowserRouter } from "react-router-dom";
import LoginPage from "./Routes/Login/LoginPage";
import ErrorPage from "./Routes/Error/ErrorPage";
import MainPage from "./Routes/Main/MainPage";
import SalesPage from "./Routes/Sales/SalesPage";
import DashBoardPage from "./Routes/DashBoard/DashBoardPage";
import Classification from "./Routes/Classification/Classification";
import ProductsPage from "./Routes/Products/ProductsPage";
import SetPasswordPage from "./Routes/SetPassword/SetPasswordPage";
import InvoicePage from "./Routes/Invoice/InvoicePage";
import TermsPage from "./Routes/Terms/TermsPage";
import TrainingPage from "./Routes/Training/TrainingPage";
import GroupsPage from "./Routes/Group/GroupsPage";
import GroupDetals from "./Routes/Group/components/GroupDetals";
import SuppliersPage from "./Routes/Suppliers/SuppliersPage";

const Router = createBrowserRouter([
  {
    path: "/",
    errorElement: <ErrorPage />,
    element: <LoginPage />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/setpassword",
    element: <SetPasswordPage />,
  },
  {
    path: "/terms",
    element: <TermsPage />,
  },
  {
    path: "/",
    element: <MainPage />,
    children: [
      {
        path: "dashboard",
        element: <DashBoardPage />,
      },
      {
        path: "sales",
        element: <SalesPage />,
      },
      {
        path: "classification",
        element: <Classification />,
      },
      {
        path: "products",
        element: <ProductsPage />,
      },
      {
        path: `invoice`,
        element: <InvoicePage />,
      },
      {
        path: `training`,
        element: <TrainingPage />,
      },
      {
        path: `groups`,
        element: <GroupsPage />,
      },
      {
        path: `groups/:id`,
        element: <GroupDetals />,
      },
      {
        path: `suppliers`,
        element: <SuppliersPage/>,
      },
    ],
  },
]);

export default Router;
