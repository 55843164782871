import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { GetApi } from "../services/Api";

export const UseFetch = <T = unknown,>(
  url: string,
  config?: AxiosRequestConfig
) => {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(true);
  const request = (customConfig?: AxiosRequestConfig | null) => {
    setLoading(true);
    GetApi<T>(url, customConfig ? customConfig : config)
      .then((response: AxiosResponse) => {
        setData(response.data);
      })
      .catch((err: AxiosError) => setError(err))
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    request();
  }, []);
  return { data, error, loading, update: request };
};
