import React from "react";
import { ProductType } from "../../../types/Products";
import { BRL } from "../../../services/Mask";
import Modal from "../../../components/Modal/Modal";
import EditProduct from "./EditProduct";
import { SimpleSearchParams } from "../../../hooks/SearchParams";
import { IsAdministrator } from "../../../services/Utils";
interface ProductComponentType {
  Product: ProductType;
  possition: number;
  updateProducts: () => void;
  maxRange: number;
  count: number;
}
const Product = ({
  Product,
  possition,
  updateProducts,
  maxRange,
  count,
}: ProductComponentType) => {
  const SearchParams = SimpleSearchParams();
  const productPercent = (Number(count) / maxRange) * 100;
  const handleClick = () => {
    SearchParams.set("modal", Product.productId);
  };
  return (
    <>
      <Modal modalName={Product.productId} onClose={updateProducts}>
        <EditProduct product_default={Product} />
      </Modal>
      <div className="product" onClick={handleClick}>
        <div className="product-possition">{possition}</div>
        <div className="product-img">
          <img src={Product.imageUrl} alt={Product.name} />
        </div>
        <div className="product-acquisition">
          <h2>{Product.name}</h2>
          <p>{BRL((Product?.acquisitionValue || 0).toFixed(2))}</p>
        </div>
        <div className="product-cont">
          <div>{count}</div>
        </div>
        <div className="product-progressbar-container">
          <div className="product-progressbar">
            <span style={{ width: `${productPercent}%` }} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Product;
