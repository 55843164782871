import React, { useState } from "react";
import HeaderComponent from "../../../components/Header/HeaderComponent";
import { InvoiceSale, InvoiceType } from "../../../types/Invoice";
import { BRL, LocaleDate } from "../../../services/Mask";
import { UseFetch } from "../../../hooks/useFetch";
import QRCode from "react-qr-code";

interface InvoiceDetailsType {
  invoice: InvoiceType;
}

const InvoiceDetail = ({ invoice }: InvoiceDetailsType) => {
  let [copied, setCopied] = useState(false);
  let { data: InvoiceProducts } = UseFetch<InvoiceSale[]>(
    `invoices/sales/${invoice.invoiceId}`
  );
  const copyToClipboard = async () => {
    if (!navigator.clipboard) {
      console.log("Imposivel copiar");
    } else {
      try {
        navigator.clipboard.writeText(invoice.pixCode);
        console.log("copiado");
        setCopied(true);
      } catch (err) {
        console.log("erro ao copiar");
      }
    }
  };
  return (
    <main id="InvoiceDetails">
      <HeaderComponent
        title="Detalhes da fatura"
        lead={`${LocaleDate(invoice.startDate)} - ${LocaleDate(
          invoice.endDate
        )}`}
      />
      <section id="container">
        <div id="sales-list">
          <header>
            <div className="sale-img"></div>
            <div className="sale-name">Nome</div>
            <div>Data</div>
            <div>QTD</div>
            <div>Valor de custo</div>
          </header>
          {/* <h2>Vendas:</h2> */}
          {InvoiceProducts &&
            InvoiceProducts.length &&
            InvoiceProducts?.map((sale) => {
              if (sale.status === "returned") return null;
              return <SaleItem key={sale.saleId} sale={sale} />;
            })}
        </div>
        {InvoiceProducts &&
          InvoiceProducts.find((sale) => sale.status === "returned") && (
            <div id="sales-list" className="defects">
              <h2>Devoluções:</h2>
              {InvoiceProducts &&
                InvoiceProducts.map((sale) => {
                  if (sale.status !== "returned") return null;
                  return <SaleItem key={sale.saleId} sale={sale} />;
                })}
            </div>
          )}
        <footer>
          <div className="sale-img"></div>
          <div></div>
          <div></div>
          <div></div>
          <div>Total: {BRL(invoice.amount.toFixed(2))}</div>
        </footer>
      </section>
      <section id="payment">
        <div className="qrcode" onClick={copyToClipboard}>
          <QRCode value={invoice.pixCode} />
          <p>{copied ? "Copiado" : "Clique para copiar"}</p>
        </div>
        <div id="infos">
          <p>Nome: Zilmar Henrique da Fonseca</p>
          <p>Banco: NuBank</p>
          <p>Valor: {BRL(invoice.amount.toFixed(2))}</p>
        </div>
      </section>
      {/* <footer>
        <button className="delete">Deletar</button>
        <button>Confirmar pagamento</button>
      </footer> */}
    </main>
  );
};

export default InvoiceDetail;

const SaleItem = ({ sale }: { sale: InvoiceSale }) => {
  return (
    <div className="sale-item">
      <div className="sale-img">
        <img src={sale.product.imageUrl} alt={sale.product.name} />
      </div>
      <div className="sale-name">{sale.product.name}</div>
      <div>{LocaleDate(sale.createdAt)}</div>
      <div>{sale.quantity}</div>
      <div>
        {sale.status === "returned" && "- "}
        {BRL((sale.acquisitionValue * sale.quantity).toFixed(2))}
      </div>
    </div>
  );
};
