import React, { useState } from "react";
import InputComponent from "../../../components/Input/InputComponent";
import { DeleteApi } from "../../../services/Api";
import { SimpleSearchParams } from "../../../hooks/SearchParams";
import { useParams } from "react-router-dom";
import HeaderComponent from "../../../components/Header/HeaderComponent";

const DeleteProduct = ({ productId }: { productId: string }) => {
  let { id } = useParams();
  let [deleteProduct, setDeleteProduct] = useState("");
  let SearchParams = SimpleSearchParams();
  let [errorMessage, setErrorMessage] = useState<string>();
  let [loading, setLoading] = useState(false);
  const handleDelete = async () => {
    if (deleteProduct !== "CONFIRMO")
      return setErrorMessage("A Confirmação deve ser realizada");
    setLoading(true);
    let response = await DeleteApi(`groups/products/${productId}`, {
      groupId: id,
    })
      .then((res) => res)
      .catch((err) => {
        console.error(err);
        setErrorMessage("Erro ao deletar o produto");
        return err;
      });
    setLoading(false);
    if (response.status === 204) {
      SearchParams.delete("delete");
      SearchParams.delete("editProduct");
    } else {
      setErrorMessage("Erro ao deletar o produto");
    }
  };
  return (
    <main id="deleteProduct">
      <HeaderComponent title="Deletar Produto" />
      <section>
        <InputComponent
          id="deleteProduct"
          label="Deletar o Produto:"
          value={deleteProduct}
          placeholder="CONFIRMO"
          autoComplete="off"
          onChange={(e) => setDeleteProduct(e.target.value.toLocaleUpperCase())}
        />
      </section>
      <footer>
        <button
          disabled={loading}
          className="secont"
          onClick={() => SearchParams.delete("delete")}
        >
          Cancelar
        </button>
        <button
          onClick={handleDelete}
          disabled={deleteProduct !== "CONFIRMO" || loading}
        >
          {loading ? "Carregando..." : "Deletar"}
        </button>
      </footer>
    </main>
  );
};

export default DeleteProduct;
