import React from "react";

const Header = () => {
  return (
    <section id="header-products">
      <div>POSIÇÃO</div>
      <div></div>
      <div className="product-name">PRODUTO</div>
      <div>QTD</div>
      <div></div>
    </section>
  );
};

export default Header;
