import React, { useEffect } from "react";
import { ProductsRankingType } from "../../../types/Products";
import { UseFetch } from "../../../hooks/useFetch";
import { SimpleSearchParams } from "../../../hooks/SearchParams";
import { StoreType } from "../../../types/Stores";
import { getLanding } from "../../../services/Utils";

const Stores = () => {
  const SearchParams = SimpleSearchParams({ store: "" });
  let storeId = SearchParams.get("store");
  const { data, update: UpdateProducts } = UseFetch<
    {stores:StoreType[]}
  >("/stores", { params: { perPage: 3,orderAttribute: "invoicing",page:1 } });
  useEffect(UpdateProducts, [storeId]);
  return (
    <section id="stores">
      <header>
        <h3>Top Lojas</h3>
      </header>
      <section id="container">
        {data?.stores.length &&
          data.stores.map((data, index) => {
            if (index > 2) return;
            return (
              <div className="store" key={index}>
                <div>
                  <img src={getLanding(data.ranking||`iron`).icon} alt={data.name} />
                </div>
                <div>
                  <p>{data.name}</p>
                </div>
                <div>
                  <p>{data.invoicing}</p>
                </div>
              </div>
            );
          })}
      </section>
    </section>
  );
};

export default Stores;
