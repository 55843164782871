import React, { ChangeEvent, DragEvent, useRef, useState } from "react";
import "./styles.css";
interface DropZoneType {
  accept?: string;
  onChange: (file: File) => void;
  message?: string;
  imagePreview?: boolean;
  required?: Boolean;
}

const DropZone = ({
  accept = "",
  onChange,
  message = "Selecione o arquivio ou solteu aqui.",
  imagePreview = false,
  required = false,
}: DropZoneType) => {
  let inputRef = useRef<HTMLInputElement>(null);
  let [currentFile, setCurrentFile] = useState<File>();
  let [previewSrc, setPreviewSrc] = useState<string>();
  let [error, setError] = useState<string | undefined>();
  const handleDrop = (element: DragEvent<HTMLDivElement>) => {
    // element.preventDefault();
    console.log(element);
  };
  const handleChange = (element: ChangeEvent<HTMLInputElement>) => {
    let { files } = element.target;
    if (!files || files.length < 1) return;
    let types = accept.split(",");
    console.log(types, files[0].type, !types.includes(files[0].type));
    setError(undefined);
    setCurrentFile(undefined);

    if (!types.includes(files[0].type)) {
      setError("Tipo de arquivo não aceito");
    }
    setCurrentFile(files[0]);
    onChange(files[0]);
    if (imagePreview) getPreviewSrc(files[0]);
  };
  const handleClick = () => {
    if (!inputRef.current) return;
    inputRef.current.click();
  };

  const getPreviewSrc = (file: File) => {
    let reader = new FileReader();
    reader.onload = (data) => {
      if (data.target?.result) {
        setPreviewSrc(data.target.result as string);
      }
    };

    reader.readAsDataURL(file);
  };

  return (
    <div
      id="drop-zone"
      className={`${error ? "error" : ""}`}
      onDrop={handleDrop}
      onClick={handleClick}
    >
      {!previewSrc && !error && <h3>{message}</h3>}
      {error && <h3 className="error">{error}</h3>}
      {!previewSrc && currentFile && <p>{currentFile.name}</p>}
      {previewSrc && <img src={previewSrc} />}
      <input
        ref={inputRef}
        type="file"
        accept={accept}
        id="dropzone"
        onChange={handleChange}
        required={required ? true : false}
      />
    </div>
  );
};

export default DropZone;
