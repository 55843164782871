import React, { useState } from "react";
import Permissions from "./Permissions";
import InputComponent from "../../../components/Input/InputComponent";
import HeaderComponent from "../../../components/Header/HeaderComponent";
import { PutApi } from "../../../services/Api";
import { useParams } from "react-router-dom";
import { SimpleSearchParams } from "../../../hooks/SearchParams";

const EditGroup = ({
  permissions,
  setPermissions,
  name,
  description,
  currentHasCommission,
  currentDefaultProducts = true,
}: {
  permissions: string[];
  setPermissions: (value: string[]) => void;
  name: string;
  description: string;
  currentHasCommission: boolean;
  currentDefaultProducts?: boolean;
}) => {
  let [currentName, setCurrentName] = useState(name);
  let [currentDescription, setCurrentDescription] = useState(description);
  let { id } = useParams();
  let SearchParams = SimpleSearchParams();
  let [hasCommission, setHasCommission] = useState(currentHasCommission);
  let [useDefaultProducts, setUseDefaultProducts] = useState(
    currentDefaultProducts
  );

  const handleSubmit = async () => {
    let response = await PutApi(`/groups/${id}`, {
      permissions,
      name: currentName,
      description: currentDescription,
      hasCommission,
      useDefaultProducts,
    });
    if (response.status === 204) SearchParams.delete("modal");
  };

  return (
    <section id="editGroup">
      <HeaderComponent title="Editar Grupo" />
      <div id="inputs">
        <InputComponent
          label="Nome:"
          id="name"
          value={currentName}
          onChange={(e) => setCurrentName(e.currentTarget.value)}
        />
        <InputComponent
          label="Descrição:"
          id="description"
          value={currentDescription}
          onChange={(e) => setCurrentDescription(e.currentTarget.value)}
        />
        <div id="hasCommission">
          O grupo é comissionado?
          <button onClick={() => setHasCommission(!hasCommission)}>
            {hasCommission ? "Sim" : "Não"}
          </button>
        </div>
        <div id="hasCommission">
          O grupo usa o catálogo padrão?
          <button onClick={() => setUseDefaultProducts(!useDefaultProducts)}>
            {useDefaultProducts ? "Sim" : "Não"}
          </button>
        </div>
      </div>
      <Permissions
        selectedPermissions={permissions}
        setSelectedPermissions={setPermissions}
      />
      <div id="buttons">
        <button className="second">Cancelar</button>
        <button
          onClick={handleSubmit}
          disabled={!permissions.length || !currentDescription || !currentName}
        >
          Atualizar
        </button>
      </div>
    </section>
  );
};

export default EditGroup;
