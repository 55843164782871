import React, { useEffect, useState } from "react";
import HeaderComponent from "../../components/Header/HeaderComponent";
import { UseFetch } from "../../hooks/useFetch";
import { TrainingVideosType } from "../../types/Training";
import VideoComponent from "./components/VideoComponent";
import { SimpleSearchParams } from "../../hooks/SearchParams";
import "./styles.css";
import FilterComponent from "../../components/Filter/FilterComponent";
import Pagination from "../../components/Pagination/PaginationComponent";
import Modal from "../../components/Modal/Modal";
import UploadVideo from "./components/UploadVideo/UploadVideo";
import DriveComponent from "../../components/DriveComponent/DriveComponent";
import CreateVideo from "./components/CreateVideo/CreateVideo";
const TrainingPage = () => {
  let SearchParams = SimpleSearchParams({ page: "1", perPage: "12" });
  let page = SearchParams.get("page");
  let perPage = SearchParams.get("perPage");
  let query = SearchParams.get("q");
  let {
    data: TrainingVideos,
    loading: loadingTrainingVideos,
    error: errorTrainingVideos,
    update: updateTrainingVideos,
  } = UseFetch<{
    totalItems: number;
    totalPages: number;
    trainingVideos: TrainingVideosType[];
  }>("/trainingVideos", { params: { page, perPage, title: query } });

  useEffect(() => {
    updateTrainingVideos();
  }, [page, perPage]);

  let [loadingTimer, setLoadingTimer] = useState<any>();
  useEffect(() => {
    if (loadingTrainingVideos) {
      clearTimeout(loadingTimer);
      setLoadingTimer(setTimeout(updateTrainingVideos, 800));
    } else {
      updateTrainingVideos();
    }
  }, [query]);
  return (
    <main id="TrainingPage">
      <HeaderComponent title="Treinamentos" search />
      <FilterComponent
        buttons={[
          {
            label: "+ Enviar Video",
            forAdmin: true,
            action: () => SearchParams.set("modal", "uploadVideo"),
          },
        ]}
      />
      <Modal modalName="uploadVideo" onClose={updateTrainingVideos}>
        <CreateVideo/>
      </Modal>
      <section id="training-videos">
        {!loadingTrainingVideos &&
          !errorTrainingVideos &&
          TrainingVideos?.trainingVideos.map((video, index) => (
            <VideoComponent
              key={index}
              video={video}
              index={index}
              updateVideos={updateTrainingVideos}
            />
          ))}
        {/* <DriveComponent />
        <VideoComponent
          key={1000}
          updateVideos={updateTrainingVideos}
          index={89}
          video={{
            imageUrl: "",
            videoUrl: "https://videos.sejadropz.com.br/video/teste.mp4",
            title: "teste vbugs",
            imageFileKey: "ewofjow",
            createdAt: new Date(),
            updatedAt: new Date(),
            trainingVideoId: "iouhewf",
            videoFileKey: "",
            description: "teste",
          }}
        /> */}
      </section>
      <Pagination
        totalItems={TrainingVideos?.totalItems || 1}
        totalPages={TrainingVideos?.totalPages || 1}
      />
    </main>
  );
};

export default TrainingPage;
