import React, { useEffect, useState } from "react";
import PIX, { payload } from "react-qrcode-pix";

const Pix = () => {
  const [qrCode, setQrCode] = useState<string>("");
  const [rawPix, setRawPix] = useState<string>("");
  const [error, setError] = useState<string>("");
  const geratePix = () => {
    try {
      const pix = payload({
        pixkey: "04427975089",
        merchant: "vinicius bugs brum",
        amount: 50000,
        city: "sapiranga",
      });
      setRawPix(pix);
    } catch (err) {
      setError("erro");
    }
  };
  useEffect(geratePix, []);
  return (
    <div>
      {/* <img src={qrCode} alt={"QR Code PIX"} /> */}
      <PIX
        pixkey={"04427975089"}
        amount={50000}
        merchant="vinicius bugs"
        city="sapiranga"
      />
      <textarea
        style={{
          minWidth: "50px",
          minHeight: "50px",
          margin: "0 auto",
          whiteSpace: "nowrap",
          overflow: "auto",
        }}
      >
        {rawPix}
      </textarea>
      <p>{error}</p>
    </div>
  );
};

export default Pix;
