import React from "react";

const DriveComponent = ({
  videoKey,
  videoUrl,
}: {
  videoKey?: string;
  videoUrl?: string;
}) => {
  return (
    <section id="video-player">
      <iframe
        onLoad={(e: React.SyntheticEvent<HTMLIFrameElement, Event>) => {
          console.log("iframe", e);
        }}
        src={videoUrl || `https://drive.google.com/file/d/${videoKey}/preview`}
        allow="autoplay"
        title="driveTeste"
        allowFullScreen
      />
    </section>
  );
};

export default DriveComponent;
