import { useEffect, useState } from "react";
import HeaderComponent from "../../../components/Header/HeaderComponent";
import InputComponent from "../../../components/Input/InputComponent";
import { SimpleSearchParams } from "../../../hooks/SearchParams";
import { Api, DeleteApi, PostApi } from "../../../services/Api";
import { BRL } from "../../../services/Mask";
import Pagination from "../../../components/Pagination/PaginationComponent";
import { UseFetch } from "../../../hooks/useFetch";
import messageSvg from "../../../assets/messageWhite.svg";

const ProductSuppliers = ({ product_default }) => {
  let [loading, setLoading] = useState(false);
  let [values, setValues] = useState({});
  let [product, setProduct] = useState({
    acquisitionValue: 0,
    manufacturingValue: 0,
    name: "",
    standardAmount: 0,
    originalManufacturingValue: 0,
  });
  const {
    data: suppliers,
    loadingSuppliers,
    update: updateSuppliers,
  } = UseFetch("suppliers");
  let [deleteProduct, setDeleteProduct] = useState("");
  let [ImgFile, setImgFile] = useState();
  let [edited, setEdited] = useState(false);
  let [errorMessage, setErrorMessage] = useState();
  let SearchParams = SimpleSearchParams();
  const handleChange = (element) => {
    setEdited(true);
    let target = element.currentTarget;
    setValues({ ...values, [target.id]: target.value });
  };
  const isAdmin = localStorage.getItem("isAdmin") === "true";
  useEffect(() => {
    let data = product_default;
    setProduct({
      productId: data.productId,
      acquisitionValue: data.acquisitionValue,
      standardAmount: data.standardAmount,
      name: data.name,
      imageUrl: data.imageUrl,
      manufacturingValue: data.manufacturingValue,
      originalManufacturingValue: data.originalManufacturingValue || 0,
      suppliers: data.suppliers,
    });
  }, []);
  const handleSubmit = async () => {
    if (!values.supplier || !values.valor)
      return setErrorMessage("Todos os campos são obrigatórios");

    setLoading(true);
    let response = await PostApi("/suppliers/products", {
      productId: product.productId,
      supplierId: values.supplier,
      amount: values.valor,
    })
      .then((res) => res)
      .catch((err) => {
        setErrorMessage("Ocorreu um erro na edição do produto");
        console.error(err);
        return err;
      });
    setLoading(false);
    if (response.status === 201) {
      SearchParams.delete("modal");
    } else {
      setErrorMessage("Ocorreu um erro na adição do fornecedor");
    }
  };
  const handleDelete = async (uid) => {
    setLoading(true);
    let response = await DeleteApi(`/suppliers/products/${uid}`)
      .then((res) => res)
      .catch((err) => {
        setErrorMessage("Ocorreu um erro na criação do fornecedor");
        console.error(err);
        return err;
      });
    setLoading(false);
    if (response.status === 204) {
      SearchParams.delete("modal");
    } else {
      setErrorMessage("Ocorreu um erro na criação do fornecedor");
    }
  };
  return (
    <main id="updateProduct">
      <HeaderComponent title="Fornecedores" lead=""/>
      <div>
        {errorMessage && <h2 className="error">{errorMessage}</h2>}
        <div style={{ display: "grid", gridTemplateColumns: "auto 1fr" }}>
          <img src={product.imageUrl} alt={product.name} height={100} />
          <InputComponent
            id="name"
            label="Nome:"
            type="text"
            onChange={handleChange}
            value={product.name}
            required
            disabled
            error={!product.name}
          />
        </div>
        {localStorage.getItem("isAdmin") === "true" && (
          <div
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                display: "flex",
                padding: "5px 8px 0 0",
                width: "100%",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <label>Fornecedor</label>
              <select onChange={handleChange} id="supplier">
                <option value={null} disabled selected>
                  Selecione
                </option>
                {suppliers?.suppliers &&
                  suppliers.suppliers.map((supplier) => (
                    <option value={supplier.supplierId}>{supplier.name}</option>
                  ))}
              </select>
            </div>
            <div style={{ padding: "0 8px", width: "100%" }}>
              <InputComponent
                id="valor"
                label="Valor:"
                type="number"
                step="0.01"
                min="0.01"
                onChange={handleChange}
                required
              />
            </div>
            <div
              style={{
                alignSelf: "end",
                paddingBottom: "10px",
                padding: "0 0 8px 0",
              }}
            >
              <button
                disabled={!values.supplier || !values.valor}
                onClick={handleSubmit}
              >
                Adicionar
              </button>
            </div>
          </div>
        )}
      </div>
      <main id="container" className={`view-list`}>
        <div
          style={{
            background: "#EDEDEF",
            color: "#1a1a1a",
            marginTop: "8px",
            padding: "16px 24px",
            display: "grid",
            gridTemplateColumns: "auto 33% 33%",
            alignItems: "center",
            boxShadow: " 2px 2px 2px #cecece50",
            borderRadius: "8px",
            cursor: "default",
          }}
        >
          <div style={{ textAlign: "center" }}>Fornecedor</div>
          <div style={{ textAlign: "center" }}>Valor</div>
          <div style={{ textAlign: "center" }}>Ações</div>
        </div>
        {product.suppliers ? (
          product.suppliers.map((supplier, index) => (
            <>
              <div
                style={{
                  background: "#faf9f9",
                  padding: "16px",
                  display: "grid",
                  gridTemplateColumns: "auto 33% 33%",
                  alignItems: "center",
                  boxShadow: " 2px 2px 2px #cecece50",
                  borderRadius: "8px",
                }}
              >
                <div style={{ textAlign: "center" }}>{supplier.name}</div>
                <div style={{ textAlign: "center" }}>
                  {BRL((supplier.supplierProduct.amount || 0).toFixed(2))}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "4px",
                  }}
                >
                  {isAdmin ? (
                    <button
                      style={{ background: "#d33c3c" }}
                      onClick={() => {
                        handleDelete(
                          supplier.supplierProduct.supplierProductId
                        );
                      }}
                    >
                      Excluir
                    </button>
                  ) : null}

                  <button
                    style={{ background: "#30D14E" }}
                    onClick={() => {
                      window.open(
                        `https://wa.me/${(supplier?.phoneNumber).replace(
                          "+",
                          ""
                        )}`,
                        "_blank"
                      );
                    }}
                  >
                    <img height={20} src={messageSvg} alt="Whatsapp" />
                  </button>
                </div>
              </div>
            </>
          ))
        ) : (
          <>
            <div
              style={{
                background: "#faf9f9",
                padding: "16px",
                display: "grid",
                gridTemplateColumns: "auto ",
                alignItems: "center",
                boxShadow: " 2px 2px 2px #cecece50",
                borderRadius: "8px",
              }}
            >
              <div style={{ textAlign: "center" }}>
                Não há nenhum fornecedor cadastrado
              </div>
            </div>
          </>
        )}
      </main>
    </main>
  );
};

export default ProductSuppliers;
