import React, { useEffect, useState } from "react";
import storeLogo from "../../../assets/teste.svg";
import Modal from "../../Modal/Modal";
import SelectStore from "./SelectStore";
import { SimpleSearchParams } from "../../../hooks/SearchParams";
import { UseFetch } from "../../../hooks/useFetch";
import { StoreType } from "../../../types/Stores";
import Separator from "../../Separator/Separator";
const Store = () => {
  const SearchParams = SimpleSearchParams();
  let storeId = SearchParams.get("store");
  let [userData, setUserData] = useState<{
    storeName: string;
  }>({
    storeName: localStorage.getItem("storeName") as string,
  });
  let { data, update } = UseFetch<StoreType>("store", { params: { storeId } });

  const OpenModalSelestStore = () => {
    if (localStorage.getItem("isAdmin") !== "true") return;
    SearchParams.set("modal", "selectStore");
  };
  useEffect(() => {
    if (!data || !data.name)
      return setUserData({
        storeName: "Administrador",
      });
    setUserData({ storeName: data.name });
  }, [data]);
  useEffect(() => {
    if (!storeId) {
      setUserData({
        storeName: localStorage.getItem("storeName") || "Administrador",
      });
    } else {
      update();
    }
  }, [storeId]);

  return (
    <>
      <section id="select-store">
        <Modal modalName="selectStore">
          <SelectStore />
        </Modal>
        <div className="img-container">
          <img src={storeLogo} alt="logo" />
        </div>
        <div
          id="select-store-container"
          className={localStorage.getItem("isAdmin") === "true" ? "admin" : ""}
          onClick={OpenModalSelestStore}
        >
          <div className="titles">
            <h3>{localStorage.getItem("username") as string}</h3>
            <h2>{userData.storeName}</h2>
          </div>
        </div>
      </section>
    </>
  );
};

export default Store;
