import React, { useState } from "react";
import { InvoiceType } from "../../../types/Invoice";
interface headerTypes {
  setSelectedInvoicing: (value: string[]) => void;
  invoices: InvoiceType[];
}
const Header = ({ setSelectedInvoicing, invoices }: headerTypes) => {
  let [isAllSelected, setIsAllSelected] = useState(false);
  let handleSelectAll = () => {
    if (isAllSelected) {
      setIsAllSelected(false);
      setSelectedInvoicing([]);
    } else {
      let invoicingPending = invoices.filter(
        (invoice) => invoice.status === "pending"
      );
      if (!invoicingPending) return;
      setIsAllSelected(true);
      setSelectedInvoicing(
        invoicingPending.map((invoicing) => invoicing.invoiceId)
      );
    }
  };
  return (
    <section id="invoice-header">
      <div className="invoice-checkbox">
        <input type="checkbox" onClick={handleSelectAll} />
      </div>
      <div className="invoice-img"></div>
      <div className="invoice-title">Nome</div>
      <div className="invoice-select">Vencimento</div>
      <div className="invoice-amount">Valor</div>
      <div className="invoice-status">Status</div>
    </section>
  );
};

export default Header;
