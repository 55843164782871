import React from "react";
import HeaderComponent from "../../components/Header/HeaderComponent";
import Invoicing from "./components/Invoicing";
import Products from "./components/Products";
import Graphic from "./components/Graphic";
import "./styles.css";
import { IsAdministrator } from "../../services/Utils";
import Stores from "./components/stores";
const DashBoardPage = () => {
  return (
    <main id="DashBoardPage">
      <HeaderComponent title="Início" />
      <main id="container" className={`${IsAdministrator()?`adm`:``}`}>
        <Invoicing />
        <Products />
        {IsAdministrator()&&<Stores/>}
        <Graphic />
      </main>
    </main>
  );
};

export default DashBoardPage;
