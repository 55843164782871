import React, { useState } from "react";
import HeaderComponent from "../../../components/Header/HeaderComponent";
import { StoreType } from "../../../types/Stores";
import { getLanding } from "../../../services/Utils";
import { BRL, PhoneNumber } from "../../../services/Mask";
import messageSvg from "../../../assets/message.svg";
import { PostApi } from "../../../services/Api";
import FilterComponent from "../../../components/Filter/FilterComponent";
import { SimpleSearchParams } from "../../../hooks/SearchParams";
import Modal from "../../../components/Modal/Modal";
import EditStore from "./EditStore";

const DetailsStore = ({
  store,
  updateStores,
}: {
  store: StoreType;
  updateStores: () => void;
}) => {
  let SearchParams = SimpleSearchParams();
  let ranking = getLanding(store.ranking);
  let [message, setMessage] = useState({ error: false, message: "" });
  let [loading, setLoading] = useState(false);

  const reSendLogin = async () => {
    setMessage({ message: "", error: false });
    if (loading) return;
    if (!store.user) return;
    setLoading(true);
    let response = await PostApi("users/resendLogin", {
      userId: store.user.userId,
    });
    setLoading(false);
    if (response.status !== 204) {
      setMessage({ message: "Senha da loja já definida", error: true });
    } else {
      setMessage({ message: "Login reenviado", error: false });
    }
  };
  if (!store) return null;
  return (
    <section id="detailsStore">
      <Modal modalName="edit" onClose={updateStores} background={false}>
        <EditStore store={store} />
      </Modal>
      <HeaderComponent title="Detalhes da loja" />

      <div className="container">
        <div className="row">
          <div className="info">
            <div>Nome:</div>
            <p>{store.name}</p>
          </div>
          <div className="info">
            <div>Ranking:</div>
            <div className="detailsStore-ranking">
              <img src={ranking.icon} alt="" />
              <p>{ranking.name}</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="info">
            <div>Telefone:</div>
            <div className="detailsStore-phone">
              {store.user && (
                <>
                  <p>
                    {PhoneNumber(
                      `${(store?.user.phoneNumber || "").replace("+55", "")}`
                    )}
                  </p>
                  <div>
                    <a
                      href={`https://wa.me/${(
                        store?.user.phoneNumber || ""
                      ).replace("+", "")}`}
                      target="blank"
                    >
                      <img src={messageSvg} alt="Whatsapp" />
                    </a>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="info">
            <div>E-mail:</div>
            {store.user && <p>{store?.user.email}</p>}
          </div>
        </div>
        <div className="row">
          <div className="info">
            <div>Nome do responsável:</div>
            {store.user && <p>{store?.user.name}</p>}
          </div>
          <div className="info">
            <div>Faturamento:</div>
            <p>{BRL(store.invoicing)}</p>
          </div>
        </div>
        <div className="row">
          <div className="info">
            <div>Grupo da loja:</div>
            {store.user && <p>{store.user.group?.name}</p>}
          </div>
          <div className="info">
            <button onClick={reSendLogin} disabled={!store.user}>
              {loading ? "Carregando..." : "Re-enviar link de login"}
            </button>
            {message.message && (
              <div className={message.error ? "error" : "success"}>
                {message.message}
              </div>
            )}
          </div>
        </div>
      </div>
      <FilterComponent
        buttons={[
          {
            label: "Editar",
            action: () => SearchParams.set("modal", "edit"),
            forAdmin: !!store.user,
          },
        ]}
      />
    </section>
  );
};

export default DetailsStore;
