import React, { useEffect, useState } from "react";
import "./styles.css";
import { UseFetch } from "../../hooks/useFetch";
import { ProductType, ProductsRankingType } from "../../types/Products";
import Product from "./components/Product";
import HeaderComponent from "../../components/Header/HeaderComponent";
import { SimpleSearchParams } from "../../hooks/SearchParams";
import FilterComponent from "../../components/Filter/FilterComponent";
import Modal from "../../components/Modal/Modal";
import CreateProduct from "./components/CreateProduct";
import Header from "./components/Header";

const ProductsPage = () => {
  const SearchParams = SimpleSearchParams({
    store: "",
    q: "",
  });
  let storeId = SearchParams.get("store");
  let query = SearchParams.get("q");
  const {
    data: ProducsRanking,
    loading,
    update: updateRanking,
  } = UseFetch<ProductsRankingType[]>("products/ranking", {
    params: { name: query },
  });
  const { data: ProducsData, update } = UseFetch<ProductType[]>("products", {
    params: { name: query },
  });
  let [maxRange, setMaxRange] = useState(0);
  let [startingPosition, setstartingPosition] = useState(1);
  let [nonRanked, setNonRanked] = useState<ProductType[]>([]);
  let viewStyle =
    SearchParams.get("viewStyle") ||
    JSON.parse(`${localStorage.getItem("productsView")}`) ||
    "grid";

  let [loadingTimer, setLoadingTimer] = useState<any>();
  useEffect(() => {
    let maxRange = 0;
    if (ProducsRanking)
      ProducsRanking.forEach((product: ProductsRankingType) => {
        if (product.count > maxRange) maxRange = product.count;
      });
    setMaxRange(maxRange);
  }, [ProducsRanking]);
  useEffect(() => {
    if (loading) {
      clearTimeout(loadingTimer);
      setLoadingTimer(setTimeout(update, 800));
    } else {
      update();
    }
  }, [storeId, query]);

  useEffect(() => {
    if (ProducsRanking && ProducsData) {
      const rankedProductIds = ProducsRanking.map((rank) => rank.productId);
      const nonRankedProducts = ProducsData.filter(
        (product) => !rankedProductIds.includes(product.productId)
      );
      setNonRanked(nonRankedProducts);
      setstartingPosition(ProducsRanking.length + 1);
    }
  }, [ProducsRanking, ProducsData]);

  return (
    <main id="ProductsPage">
      <HeaderComponent title="Produtos" search={"Buscar"} />
      <FilterComponent
        viewName="productsView"
        buttons={[
          {
            action: () => SearchParams.set("modal", "createProduct"),
            label: "+ Criar Produto",
            forAdmin: true,
          },
        ]}
      />

      <main id="container" className={`view-${viewStyle}`}>
        <Modal
          modalName="createProduct"
          onClose={() => {
            updateRanking();
            update();
          }}
        >
          <CreateProduct />
        </Modal>
        {/* <LoadingComponent isLoading={loading} /> */}
        {viewStyle === "list" && <Header />}
        {ProducsRanking &&
          ProducsData &&
          ProducsRanking.map((productRank, index) => {
            let product = ProducsData.find(
              (product: ProductType) =>
                product.productId === productRank.productId
            );
            if (!product) return null;
            return (
              <Product
                maxRange={maxRange}
                key={index}
                count={productRank.count}
                Product={product}
                possition={index + 1}
                updateProducts={update}
              />
            );
          })}
        {nonRanked.map((product, index) => {
          return (
            <Product
              maxRange={maxRange}
              key={index}
              count={0}
              Product={product}
              possition={index + startingPosition}
              updateProducts={update}
            />
          );
        })}
      </main>
    </main>
  );
};

export default ProductsPage;
