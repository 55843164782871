import React, { useEffect, useState } from "react";
import "./styles.css";
import Modal from "../../components/Modal/Modal";
import CreateSale from "./components/CreateSale";
import { UseFetch } from "../../hooks/useFetch";
import Sale from "./components/Sale";
import LoadingComponent from "../../components/Loading/LoadingComponent";
import Pagination from "../../components/Pagination/PaginationComponent";
import HeaderComponent from "../../components/Header/HeaderComponent";
import { SaleType } from "../../types/Sales";
import FilterComponent from "../../components/Filter/FilterComponent";
import { SimpleSearchParams } from "../../hooks/SearchParams";
import DeleteSales from "./components/DeleteSales";
import SalesHeader from "./components/SalesHeader";
import { DefaultDate } from "../../services/Mask";
import { MonthRange } from "../../services/Utils";
import trashIcon from "../../assets/icons/trash.svg";
import Pix from "./components/Pix";
interface SaleResponse {
  sales: SaleType[];
  totalPages: number;
  totalItems: number;
}

const SalesPage = () => {
  let currentMonth = MonthRange({});

  const SearchParams = SimpleSearchParams({
    page: "1",
    perPage: "10",
    status: "",
    store: "",
    startDate: DefaultDate(currentMonth.startDate),
    endDate: DefaultDate(currentMonth.endDate),
  });
  const [selectedSales, setSelectedSales] = useState<string[]>([]);

  let page = SearchParams.get("page") || 1;
  let perPage = SearchParams.get("perPage") || 0;
  let storeId = SearchParams.get("store");
  let status = SearchParams.get("status");
  let startDate = SearchParams.get("startDate");
  let endDate = SearchParams.get("endDate");
  let {
    data: SalesData,
    loading: SalesLoading,
    update: UpdateSales,
  } = UseFetch<SaleResponse>("/sales", {
    params: {
      page,
      perPage,
      status,
      storeId,
      startDate,
      endDate,
    },
  });
  useEffect(() => {
    if (SearchParams.get("modal") === "deleteSales" && !selectedSales.length) {
      SearchParams.delete("modal");
    }
  }, [SearchParams.get("modal")]);

  useEffect(() => {
    UpdateSales();
  }, [page, perPage, status, storeId, startDate, endDate]);
  return (
    <>
      <Modal modalName="createSale">
        <CreateSale updateSales={UpdateSales} />
      </Modal>
      <Modal modalName="pix">
        <Pix />
      </Modal>
      <Modal modalName="deleteSales">
        <DeleteSales
          selectedSales={selectedSales}
          updateSales={UpdateSales}
          setSelectedSales={setSelectedSales}
        />
      </Modal>
      <main id="SalesPage">
        <HeaderComponent title="Vendas" />
        <FilterComponent
          perPage
          dateFilter="dayByDay"
          buttons={[
            {
              icon: trashIcon,
              forAdmin: true,
              className: "delete",
              label: "Deletar vendas",
              action: () => SearchParams.set("modal", "deleteSales"),
              hidden: !selectedSales.length,
            },
            {
              forAdmin: true,
              // forUser: true,
              label: "+ Adicionar vendas",
              action: () => SearchParams.set("modal", "createSale"),
            },
          ]}
          status={[
            { label: "Todas", value: "" },
            { label: "Pagas", value: "paid" },
            { label: "Pendentes", value: "pending" },
            { label: "Devolvido", value: "returned" },
            { label: "Defeito", value: "defect" },
          ]}
        />
        {/* <Filters /> */}
        <section id="products">
          <SalesHeader />
          <div id="Container">
            <LoadingComponent isLoading={SalesLoading} />
            {!SalesLoading &&
              SalesData?.sales.map((sale) => {
                return (
                  <Sale
                    selectedSales={selectedSales}
                    setSelectedSales={setSelectedSales}
                    key={sale.saleId}
                    updateSales={UpdateSales}
                    sale={sale}
                  />
                );
              })}
          </div>
        </section>
        <Pagination
          totalItems={SalesData?.totalItems || 0}
          totalPages={SalesData?.totalPages || 1}
        />
      </main>
    </>
  );
};

export default SalesPage;
