import React from "react";
import { UseFetch } from "../../../hooks/useFetch";
import { GroupMemberType } from "../../../types/Groups";
import LoadingComponent from "../../../components/Loading/LoadingComponent";

const GroupMembers = ({ groupId }: { groupId: string }) => {
  let { data: members, loading } = UseFetch<GroupMemberType[]>(
    `/groups/users/${groupId}`
  );
  return (
    <section className="groupMembers">
      {members?.length === 0 && <h3 id="clean">Sem membros</h3>}
      <LoadingComponent isLoading={loading} />
      <div className="container">
        {members &&
          members.map((member) => (
            <div className="member">{member.store.name}</div>
          ))}
      </div>
    </section>
  );
};

export default GroupMembers;
