import React from "react";
import { TrainingVideosType } from "../../../types/Training";
import ReactPlayer from "react-player";

const VideoPlayer = ({ video }: { video: TrainingVideosType }) => {
  return (
    <section id="video-player">
      {/* <section id="video-container"> */}
      <ReactPlayer
        url={video.videoUrl}
        light={
          <img
            style={{ maxWidth: "80dvw", maxHeight: "75dvh" }}
            src={video.imageUrl}
            alt="Thumbnail"
          />
        }
        controls
        config={{
          file: {
            attributes: {
              controlsList: "nodownload",
            },
          },
        }}
        width={"70vw"}
        height={"minmax(auto,15vh)"}
        playing={true}
        // config={controlsList : "nodownload"}
        loop={false}
      />
      {/* </section> */}
    </section>
  );
};

export default VideoPlayer;
