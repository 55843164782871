import React, { useState } from "react";

const useLocalStorage = <T = unknown,>(key: string, initialValue: T) => {
  let [state, setState] = useState(getValue(key));

  const setValue = (value: any) => {
    try {
      setState(value);
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.log(error);
    }
  };

  function getValue(key: string) {
    try {
      let currentValue = window.localStorage.getItem(key);
      return currentValue ? JSON.parse(currentValue) : initialValue;
    } catch (error) {
      return initialValue;
    }
  }

  return [state, setValue];
};

export default useLocalStorage;
