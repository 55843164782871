import React from "react";

const SalesHeader = () => {
  return (
    <div id="header">
      {/* <div>
      <input type="checkbox" name="" id="" />
    </div> */}
      <div className="select"></div>
      <div className="img"></div>
      <div id="product">PRODUTO</div>
      <div className="store-name">LOJA</div>
      <div className="date">DATA</div>
      <div className="quantity">QTD</div>
      <div className="price">PREÇO</div>
      <div className="invoicing">LUCRO</div>
      <div>ETIQUETA</div>
    </div>
  );
};

export default SalesHeader;
