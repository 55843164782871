import React, { FormEvent, useState } from "react";
import HeaderComponent from "../../../components/Header/HeaderComponent";
import InputComponent from "../../../components/Input/InputComponent";
import { PostApi } from "../../../services/Api";
import { SimpleSearchParams } from "../../../hooks/SearchParams";
import LoadingComponent from "../../../components/Loading/LoadingComponent";

const ForgotPassword = () => {
  const SearchParams = SimpleSearchParams();
  let [message, setMessage] = useState("");
  let [email, setEmail] = useState("");
  let [loading, setLoading] = useState(false);
  const handleForgotPassword = (Element: FormEvent<HTMLFormElement>) => {
    Element.preventDefault();
    if (loading) return;
    setLoading(true);
    setMessage("");
    PostApi("auth/forgot-password", { email: email.toLowerCase() })
      .then(({ status }) => {
        if (status === 200) {
          SearchParams.set("email", email);
          SearchParams.set("modal", "reset");
        } else if (status === 404) {
          setMessage("Email não encontrado");
        } else {
          setMessage("Erro desconhecido");
        }
      })
      .catch(() => {
        setMessage("Erro desconhecido");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <section id="ForgotPassword">
      <HeaderComponent title="Redefinição de senha" />
      <section className="container">
        <header>
          <div className="img"></div>
          <div className="content">
            <h1>Redefinir senha</h1>
            <h3>
              Digite seu endereço de e-mail registrado para redefinir a senha
            </h3>
          </div>
        </header>
        <h2>{message}</h2>
        <LoadingComponent isLoading={loading} />
        <form onSubmit={handleForgotPassword}>
          <InputComponent
            id="email"
            label="Email"
            type="email"
            value={email}
            onChange={(element: FormEvent<HTMLInputElement>) => {
              setEmail(element.currentTarget.value.toLowerCase());
            }}
            required
          />
          <div className="buttons">
            <button type="submit">Enviar</button>
          </div>
        </form>
      </section>
    </section>
  );
};

export default ForgotPassword;
