import React, { useEffect } from "react";
import "./styles.css";
import arrow from "../../assets/icons/arrow.svg";
import duble_arrow from "../../assets/icons/duble-arrow.svg";
import { useSearchParams } from "react-router-dom";

type PaginationTypes = {
  totalPages: number;
  totalItems: number;
};
const Pagination = ({ totalPages = 1, totalItems = 0 }: PaginationTypes) => {
  let [SearchParams, SetSearchParams] = useSearchParams();
  let CurrentPage = Number(SearchParams.get("page")) || 1;
  const SetPage = (page: number | string) => {
    SearchParams.set("page", `${page}`);
    SetSearchParams(SearchParams);
  };

  useEffect(() => {
    if (!SearchParams.get("page")) {
      SetPage(1);
    }
  }, []);
  return (
    <section id="pagination">
      <div
        onClick={() => {
          if (CurrentPage - 5 >= 1) {
            SetPage(CurrentPage - 5);
          } else if (CurrentPage - 5 < 1) {
            SetPage(1);
          }
        }}
      >
        <img src={duble_arrow} alt="" />
      </div>

      <div
        onClick={() => {
          if (CurrentPage === 1) return;
          SetPage(CurrentPage - 1);
        }}
      >
        <img src={arrow} alt="" />
      </div>
      {CurrentPage > 2 && CurrentPage >= totalPages && (
        <div onClick={() => SetPage(CurrentPage - 2)}>{CurrentPage - 2}</div>
      )}
      {CurrentPage > 1 && (
        <div onClick={() => SetPage(CurrentPage - 1)}>{CurrentPage - 1}</div>
      )}
      <div className="active">{CurrentPage}</div>
      {CurrentPage < totalPages && (
        <div onClick={() => SetPage(CurrentPage + 1)}>{CurrentPage + 1}</div>
      )}
      {CurrentPage === 1 && CurrentPage + 2 <= totalPages && (
        <div onClick={() => SetPage(CurrentPage + 2)}>{CurrentPage + 2}</div>
      )}
      <div
        className="reverse"
        onClick={() => {
          if (CurrentPage === totalPages) return;
          SetPage(CurrentPage + 1);
        }}
      >
        <img src={arrow} alt="" />
      </div>
      <div
        className="reverse"
        onClick={() => {
          if (CurrentPage + 5 <= totalPages) {
            SetPage(CurrentPage + 5);
          } else if (CurrentPage !== totalPages) {
            SetPage(totalPages);
          }
        }}
      >
        <img src={duble_arrow} alt="" />
      </div>
    </section>
  );
};

export default Pagination;
