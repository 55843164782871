import React, { ChangeEvent } from "react";
import { BRL, LocaleDate } from "../../../services/Mask";
import { PutApi } from "../../../services/Api";
import { SaleType } from "../../../types/Sales";

const Sale = ({
  sale,
  updateSales,
  selectedSales,
  setSelectedSales,
}: {
  sale: SaleType;
  updateSales: () => void;
  selectedSales: string[];
  setSelectedSales: (sales: string[]) => void;
}) => {
  let isChecked = !!selectedSales.find((saleId) => saleId === sale.saleId);
  const handleChangeStatus = (element: ChangeEvent<HTMLSelectElement>) => {
    let { value } = element.currentTarget;
    PutApi(`/sales/${sale.saleId}${value === "returned" ? "/returned" : ""}`, {
      status: value,
    })
      .then(() => {})
      .catch(() => {})
      .finally(() => updateSales());
  };
  const handleChangeChecked = () => {
    if (isChecked) {
      setSelectedSales(
        selectedSales.filter((saleId) => saleId !== sale.saleId)
      );
    } else {
      setSelectedSales([...selectedSales, sale.saleId]);
    }
  };
  const status = {
    pending: "Pendente",
    defect: "Defeito",
    returned: "Devolvido",
    paid: "Pago",
  };
  const formatDate = () => {
    let formated = LocaleDate(sale.saleDate).split("/");
    return `${formated[0]}/${formated[1]}`;
  };
  return (
    <div className="sale">
      <div className={`select ${sale.store.isFactory ? `factory` : ``}`}>
        <input
          disabled={sale.store.isFactory || sale.belongsToInvoice}
          type="checkbox"
          defaultChecked={isChecked}
          onClick={handleChangeChecked}
        />
      </div>
      <div className="img">
        <img src={sale.product.imageUrl} alt="" />
      </div>
      <div className="product-name">{sale.product.name}</div>
      <div className="store-name">{sale.store.name}</div>
      <div className="date">{formatDate()}</div>
      <div>{sale.quantity}</div>
      <div className="price">{BRL(`${sale.amount.toFixed(2)}`)}</div>
      <div
        className={`invoicing value-${
          sale.invoicing <= 0 ? "negative" : "positive"
        }`}
      >
        {BRL(`${sale.invoicing.toFixed(2)}`)}
      </div>
      <div
        className={`invoicing-mobile value-${
          sale.invoicing <= 0 ? "negative" : "positive"
        }`}
      >
        {BRL(`${sale.invoicing.toFixed(2)}`).replace("R$ ", "")}
      </div>
      <div className="status">
        {localStorage.getItem("isAdmin") !== "true" ? (
          <p className={`status-p ${sale.status}`}>{status[sale.status]}</p>
        ) : (
          <select
            className={`status-select ${sale.status}`}
            disabled={
              (sale.status !== "pending" && sale.status !== "paid") ||
              sale.store.isFactory
            }
            onChange={handleChangeStatus}
            value={sale.status}
          >
            <option className="paid" value="paid">
              Pago
            </option>
            <option className="defect" value="defect">
              Defeito
            </option>
            <option className="returned" value="returned">
              Devolvido
            </option>
            <option value="pending" disabled>
              Pendente
            </option>
          </select>
        )}
      </div>
    </div>
  );
};

export default Sale;
