import React, { FormEvent, useEffect, useState } from "react";
import "./styles.css";
import logo from "../../assets/teste.svg";
import previwImage from "../../assets/preview.png";
import InputComponent from "../../components/Input/InputComponent";
import { GetApi, PostApi } from "../../services/Api";
import { useNavigate, useSearchParams } from "react-router-dom";
import Modal from "../../components/Modal/Modal";
import SetPassword from "./components/SetPassword";
import { StoreType } from "../../types/Stores";
import { SimpleSearchParams } from "../../hooks/SearchParams";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";

type User = {
  email?: string;
  password?: string;
};
type LoginResponse = {
  accessToken: string;
  expiresIn: number;
  idToken: string;
  refreshToken: string;
  tokenType: "Bearer";
  isAdmin: boolean;
  userId: string;
  name: string;
  email: string;
  createdAt: string;
  updatedAt: string;
  session: string;
  message?: string;
  store?: StoreType;
  permissions: string[];
};
const LoginPage = () => {
  const Navigate = useNavigate();
  const SearchParams = SimpleSearchParams();
  let [loading, setLoading] = useState(false);
  let [user, setUser] = useState<User>({});
  let [ErrorMessage, setErrorMessage] = useState("");

  const autoLogin = () => {
    if (localStorage.getItem("refreshToken"))
      PostApi<StoreType>("/refresh-token", {
        refreshToken: localStorage.getItem("refreshToken"),
      }).then(({ data, status }) => {
        if (status === 200) {
          Navigate("/dashboard");
        }
      });
  };

  useEffect(() => {
    autoLogin();
  }, []);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    setErrorMessage("");
    PostApi<LoginResponse>("/auth", {
      email: user.email,
      password: user.password,
    })
      .then((response) => {
        let { status, data } = response;
        if (status === 200) {
          window.localStorage.setItem("username", data.name);
          window.localStorage.setItem("email", data.email);
          if (!data.idToken) {
            window.localStorage.setItem("session", data.session);
            SearchParams.set("modal", "setPassword");
            return;
          }
          window.localStorage.setItem("storeName", data.store?.name || "");
          window.localStorage.setItem("userToken", data.idToken);
          window.localStorage.setItem("refreshToken", data.refreshToken);
          window.localStorage.setItem("isAdmin", `${data.isAdmin}`);
          window.localStorage.setItem(
            "permissions",
            JSON.stringify(data.permissions)
          );
          Navigate("/dashboard");
        }
        if (status !== 200) {
          if (data.message === "Incorrect Email Or Password") {
            setErrorMessage("Email ou senha incorretos");
          } else {
            setErrorMessage("Erro desconecido");
          }
        }
      })
      .catch((err) => {
        if (err.status) {
          setErrorMessage("Erro inesperado");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleChange = (element: FormEvent<HTMLInputElement>) => {
    if (!element.target) return;
    let { id, value } = element.currentTarget;
    setUser({ ...user, [id]: value });
  };
  return (
    <>
      <Modal modalName="setPassword">
        <SetPassword />
      </Modal>
      <Modal modalName="forgot">
        <ForgotPassword />
      </Modal>
      <Modal modalName="reset">
        <ResetPassword />
      </Modal>
      <main id="LoginPage">
        <section id="Login">
          <section id="header">
            <img src={logo} alt="" />
            <h1>Bem Vindo!</h1>
            <h3>Transforme interações em oportunidades</h3>
          </section>
          <section>
            <form onSubmit={handleSubmit}>
              <div id="Error">{ErrorMessage}</div>
              <InputComponent
                id="email"
                type="email"
                label="E-mail"
                required={true}
                value={user["email"]}
                onChange={handleChange}
              />
              <InputComponent
                id="password"
                type="password"
                label="Senha"
                minLength={8}
                required={true}
                value={user["password"]}
                onChange={handleChange}
              />
              <button type="submit" disabled={loading}>{!loading?'Entrar':'Carregando...'}</button>
            </form>
          </section>
          <section id="options">
            <button
              className="third"
              onClick={() => SearchParams.set("modal", "forgot")}
            >
              Esqueci minha senha
            </button>
          </section>
        </section>
        <section id="image">
          <img src={previwImage} alt="" />
        </section>
      </main>
    </>
  );
};

export default LoginPage;
