import Modal from "../../../components/Modal/Modal";
import ProductSuppliers from "./ProductSuppliers";
import { SimpleSearchParams } from "../../../hooks/SearchParams";
import { IsAdministrator } from "../../../services/Utils";

const Product = ({
  Product,
  possition,
  updateProducts,
  maxRange,
  count,
}) => {
  const SearchParams = SimpleSearchParams();
  const productPercent = (Number(count) / maxRange) * 100;
  const handleClick = () => {
    SearchParams.set("modal", Product.productId);
  };
  return (
    <>
      <Modal modalName={Product.productId} onClose={updateProducts}>
        <ProductSuppliers product_default={Product} />
      </Modal>
      <div className="product" onClick={handleClick}>
        <div className="product-possition">{possition}</div>
        <div className="product-img">
          <img src={Product.imageUrl} alt={Product.name} />
        </div>
        <div className="product-acquisition">
          <h2 style={{textAlign: "center"}}>{Product.name}</h2>
        </div>
        <div className="product-cont">
          <div>{count}</div>
        </div>
        <div className="product-progressbar-container">
          <div className="product-progressbar">
            <span style={{ width: `${productPercent}%` }} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Product;
