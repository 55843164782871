import React, { useEffect, useState } from "react";
import HeaderComponent from "../../../components/Header/HeaderComponent";
import { UseFetch } from "../../../hooks/useFetch";
import { useParams } from "react-router-dom";
import { ProductType } from "../../../types/Products";
import Product from "./Product";
import FilterComponent from "../../../components/Filter/FilterComponent";
import { SimpleSearchParams } from "../../../hooks/SearchParams";
import Modal from "../../../components/Modal/Modal";

const GroupCatalog = () => {
  let { id } = useParams();
  let searchParams = SimpleSearchParams();
  let [products, setProducts] = useState<ProductType[]>([]);
  let { data: productsData, update } = UseFetch<ProductType[]>("/products", {
    params: { groupId: id },
  });

  useEffect(() => {
    if (!productsData?.length) return;
    setProducts(productsData);
  }, [productsData]);
  return (
    <>
      <main id="groupCatalog">
        <HeaderComponent title="Catálogo" />
        <FilterComponent
          buttons={[
            {
              label: "Adicionar",
              action: () => {
                searchParams.set("modal", "addProduct");
              },
              forAdmin: true,
            },
          ]}
        />
        <section>
          {products.length ? (
            <div className="container">
              {products.map((product) => (
                <Product
                  Product={product}
                  handleClick={() => {
                    searchParams.set("editProduct", product.productId);
                  }}
                  updateProducts={update}
                />
              ))}
            </div>
          ) : (
            <div className="clear">
              <h3>Sem produtos</h3>
            </div>
          )}
        </section>
      </main>
    </>
  );
};

export default GroupCatalog;
