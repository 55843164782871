import React from "react";

let months = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];
interface HeaderType {
  setSelectedMonth: (value: number) => any;
  selectedMonth: number;
}
const Header = ({ setSelectedMonth, selectedMonth }: HeaderType) => {
  let currentDate = new Date();
  return (
    <section id="header">
      <header>
        <div>
          <div>{months[new Date(0, selectedMonth, 1).getMonth()]}</div>
          <div>
            {new Date(
              currentDate.getFullYear(),
              selectedMonth,
              1
            ).getFullYear()}
          </div>
        </div>
        <div className="buttons">
          <div onClick={() => setSelectedMonth(--selectedMonth)}>{"<"}</div>
          <div onClick={() => setSelectedMonth(currentDate.getMonth())}>
            {"Hoje"}
          </div>
          <div onClick={() => setSelectedMonth(++selectedMonth)}>{">"}</div>
        </div>
      </header>
      <section id="days">
        <div>Dom</div>
        <div>Seg</div>
        <div>Ter</div>
        <div>Qua</div>
        <div>Qui</div>
        <div>Sex</div>
        <div>Sab</div>
      </section>
    </section>
  );
};

export default Header;
