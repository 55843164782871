import React from "react";
import { GroupPermissionsType } from "../../../types/Groups";
import { UseFetch } from "../../../hooks/useFetch";
import LoadingComponent from "../../../components/Loading/LoadingComponent";
interface permissionsType {
  setSelectedPermissions: (value: string[]) => void;
  selectedPermissions: string[];
}
const Permissions = ({
  selectedPermissions,
  setSelectedPermissions,
}: permissionsType) => {
  let { data: permissions, loading } =
    UseFetch<GroupPermissionsType[]>("/permissions");

  let addPermission = (id: string) => {
    if (selectedPermissions.includes(id)) return;
    setSelectedPermissions([...selectedPermissions, id]);
  };
  let removePermission = (id: string) => {
    setSelectedPermissions(
      selectedPermissions.filter((currentId) => currentId !== id)
    );
  };
  return (
    <section className="groupPermissions">
      <h1>Permissões do Grupo</h1>
      <LoadingComponent isLoading={loading} />
      {permissions &&
        permissions.map((permission) => (
          <PermissionLine
            name={permission.title}
            selected={selectedPermissions.includes(permission.permissionId)}
            id={permission.permissionId}
            add={addPermission}
            remove={removePermission}
          />
        ))}
    </section>
  );
};

export default Permissions;

const PermissionLine = ({
  name,
  selected,
  id,
  remove,
  add,
}: {
  name: string;
  selected: boolean;
  id: string;
  remove: (id: string) => void;
  add: (id: string) => void;
}) => {
  return (
    <div className="permissionLine">
      <p>{name}</p>
      <div className="buttons">
        <button className={selected ? "" : "second"} onClick={() => add(id)}>
          Sim
        </button>
        <button className={selected ? "second" : ""} onClick={() => remove(id)}>
          Não
        </button>
      </div>
    </div>
  );
};
