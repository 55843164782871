import React, { useState } from "react";
import "./style.css";
import ArrowIcon from "./assets/icons/arrow.svg";
import BoardIcon from "./assets/icons/board.svg";
import GraphicIcon from "./assets/icons/graphic.svg";
import indicationsIcon from "./assets/icons/indications.svg";
import invoiceIcon from "./assets/icons/invoice.svg";
import trainingIcon from "./assets/icons/training.svg";
import SalesIcon from "./assets/icons/sales.svg";
import GroupIcon from "./assets/icons/group.svg";
import Store from "./components/Store";
import NavButtons from "./components/NavButtons";
import Separator from "../Separator/Separator";
import OptionsComponent from "./components/OptionsComponent";
import MetaComponet from "../Meta/MetaComponet";
import { IsAdministrator } from "../../services/Utils";
import { SimpleSearchParams } from "../../hooks/SearchParams";
import useLocalStorage from "../../hooks/useLocalStorege";

const SideBar = () => {
  let SearchParams = SimpleSearchParams();
  let [permissions] = useLocalStorage("permissions", []);
  let [classActive, setClassActive] = useState(false);

  let selectedStore = SearchParams.get(`store`);

  return (
    <section id="SideBar" className={classActive ? "active" : ""}>
      <span id="selector" onClick={() => setClassActive(!classActive)}>
        <img src={ArrowIcon} alt="" />
      </span>
      <span>
        <Store />
        {/* <Search /> */}
        <Separator title="PRINCIPAL" />
        <nav>
          <NavButtons
            onClick={() => setClassActive(false)}
            Title="Início"
            LinkTo="/dashboard"
            icon={BoardIcon}
          />
          <NavButtons
            onClick={() => setClassActive(false)}
            Title="Classificação"
            LinkTo="/classification"
            icon={GraphicIcon}
            visible={!permissions.length || permissions.includes("stores")}
          />
          <NavButtons
            onClick={() => setClassActive(false)}
            Title="Produtos"
            LinkTo="/products"
            icon={indicationsIcon}
            visible={!permissions.length || permissions.includes("products")}
          />
          <NavButtons
            onClick={() => setClassActive(false)}
            Title="Grupos"
            LinkTo="/groups"
            icon={GroupIcon}
            visible={IsAdministrator()}
          />
          <NavButtons
            onClick={() => setClassActive(false)}
            Title="Vendas"
            LinkTo="/sales"
            icon={SalesIcon}
            visible={!permissions.length || permissions.includes("sales")}
          />
          <NavButtons
            onClick={() => setClassActive(false)}
            Title="Faturas"
            LinkTo="/invoice"
            icon={invoiceIcon}
            visible={!permissions.length || permissions.includes("invoices")}
          />

          <NavButtons
            onClick={() => setClassActive(false)}
            Title="Treinamentos"
            LinkTo="/training"
            icon={trainingIcon}
            visible={!permissions.length || permissions.includes("training")}
          />

          <NavButtons
            onClick={() => setClassActive(false)}
            Title="Fornecedores"
            LinkTo="/suppliers"
            icon={GroupIcon}
            visible={!permissions.length || permissions.includes("suppliers")}
          />
        </nav>
        {(!IsAdministrator() || !!selectedStore) && (
          <Separator title="RANKING" />
        )}
        {(!IsAdministrator() || !!selectedStore) && <MetaComponet />}
      </span>
      <OptionsComponent />
    </section>
  );
};

export default SideBar;
