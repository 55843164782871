import React from "react";
import { GroupType } from "../../../types/Groups";
import { Link } from "react-router-dom";

const Group = ({
  group,
  updateGroups,
}: {
  group: GroupType;
  updateGroups: () => void;
}) => {
  return (
    <Link to={group.groupId}>
      <div className="group">{group.name}</div>
    </Link>
  );
};

export default Group;
