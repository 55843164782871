import { useEffect, useState } from "react";
import "./styles.css";
import { UseFetch } from "../../hooks/useFetch";
import Product from "./components/Product";
import HeaderComponent from "../../components/Header/HeaderComponent";
import { SimpleSearchParams } from "../../hooks/SearchParams";
import FilterComponent from "../../components/Filter/FilterComponent";
import Modal from "../../components/Modal/Modal";
import ManageSuppliers from "./components/ManageSuppliers";
import Header from "./components/Header";

const SuppliersPage = () => {
  const SearchParams = SimpleSearchParams({
    store: "",
    q: "",
  });
  let storeId = SearchParams.get("store");
  let query = SearchParams.get("q");
  const {
    data: ProducsRanking,
    loading,
    update: updateRanking,
  } = UseFetch("products/ranking", {
    params: { name: query },
  });
  const { data: ProducsData, update } = UseFetch("products", {
    params: { name: query },
  });
  let [maxRange, setMaxRange] = useState(0);
  let [startingPosition, setstartingPosition] = useState(1);
  let [nonRanked, setNonRanked] = useState([]);
  let viewStyle = "grid";

  let [loadingTimer, setLoadingTimer] = useState();

  useEffect(() => {
    let maxRange = 0;
    if (ProducsRanking && ProducsRanking.length > 0)
      ProducsRanking.forEach((product) => {
        if (product.count > maxRange) maxRange = product.count;
      });
    setMaxRange(maxRange);
  }, [ProducsRanking]);

  useEffect(() => {
    if (loading) {
      clearTimeout(loadingTimer);
      setLoadingTimer(setTimeout(update, 800));
    } else {
      update();
    }
  }, [storeId, query]);

  useEffect(() => {
    if (ProducsRanking && ProducsData && ProducsRanking.length > 0) {
      const rankedProductIds = ProducsRanking.map((rank) => rank.productId);
      const nonRankedProducts = ProducsData.filter(
        (product) => !rankedProductIds.includes(product.productId)
      );
      setNonRanked(nonRankedProducts);
      setstartingPosition(ProducsRanking.length + 1);
    }
  }, [ProducsRanking, ProducsData]);

  return (
    <main id="ProductsPage">
      <HeaderComponent title="Fornecedores" search/>
      <FilterComponent
        buttons={[
          {
            action: () => SearchParams.set("modal", "gerenciarFornecedores"),
            label: "Gerenciar fornecedores",
            forAdmin: true,
          },
        ]}
        gridOnly
      />

      <main id="container" className={`view-${viewStyle}`}>
        <Modal
          modalName="gerenciarFornecedores"
          onClose={() => {
            updateRanking();
            update();
          }}
        >
          <ManageSuppliers />
        </Modal>
        {viewStyle === "list" && <Header />}
        {ProducsRanking &&
          ProducsRanking.length > 0 &&
          ProducsData &&
          ProducsRanking.map((productRank, index) => {
            let product = ProducsData.find(
              (product) => product.productId === productRank.productId
            );
            if (!product) return null;
            return (
              <Product
                maxRange={maxRange}
                key={index}
                count={productRank.count}
                Product={product}
                possition={index + 1}
                updateProducts={update}
              />
            );
          })}
        {nonRanked.map((product, index) => {
          return (
            <Product
              maxRange={maxRange}
              key={index}
              count={0}
              Product={product}
              possition={index + startingPosition}
              updateProducts={update}
            />
          );
        })}
      </main>
    </main>
  );
};

export default SuppliersPage;
