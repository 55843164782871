import React, { useEffect, useState } from "react";
import HeaderComponent from "../../components/Header/HeaderComponent";
import FilterComponent from "../../components/Filter/FilterComponent";
import { SimpleSearchParams } from "../../hooks/SearchParams";
import Modal from "../../components/Modal/Modal";
import CreateInvoicing from "./components/CreateInvoicing";
import { UseFetch } from "../../hooks/useFetch";
import { InvoiceType } from "../../types/Invoice";
import "./styles.css";
import Invoice from "./components/Invoice";
import Header from "./components/Header";
import trashIcon from "../../assets/icons/trash.svg";
import DeleteInvoices from "./components/DeleteInvoices";
import Pagination from "../../components/Pagination/PaginationComponent";

const InvoicePage = () => {
  const SearchParams = SimpleSearchParams({
    page: `1`,
    perPage: `10`,
    status: ``,
    store: ``,
    startDate: ``,
    endDate: ``,
  });
  let page = SearchParams.get(`page`);
  let perPage = SearchParams.get(`perPage`);
  let status = SearchParams.get(`status`);
  let storeId = SearchParams.get(`store`);
  let startDate = SearchParams.get(`startDate`);
  let endDate = SearchParams.get(`endDate`);
  let [selectedInvoicing, setSelectedInvoicing] = useState<string[]>([]);
  let {
    data: InvoiceData,
    update: UpdateInvoice,
    loading: InvoiceLoading,
  } = UseFetch<{ totalItems: 3; totalPages: 1; invoices: InvoiceType[] }>(
    `/invoices`,
    {
      params: {
        page,
        perPage,
        status,
        storeId,
        startDate,
        endDate,
      },
    }
  );

  useEffect(() => {
    if (InvoiceLoading) return;
    UpdateInvoice();
  }, [page, perPage, status, storeId, startDate, endDate]);

  return (
    <>
      <Modal modalName="createInvoicing">
        <CreateInvoicing updateInvoicing={UpdateInvoice} />
      </Modal>
      <Modal modalName="deleteInvoicing">
        <DeleteInvoices
          selectedInvoices={selectedInvoicing}
          updateInvoices={UpdateInvoice}
        />
      </Modal>
      <main id="InvoicePage">
        <HeaderComponent title="Faturas" />

        <FilterComponent
          dateFilter="dayByDay"
          perPage
          buttons={[
            {
              hidden: !selectedInvoicing.length,
              label: "Deletar",
              action: () => SearchParams.set(`modal`, `deleteInvoicing`),
              forAdmin: true,
              icon: trashIcon,
              className: "delete",
            },
            {
              label: `+ Adicionar Faturas`,
              action: () => SearchParams.set(`modal`, `createInvoicing`),
              forAdmin: true,
            },
          ]}
          status={[
            { label: "Todas", value: "" },
            { label: "Pagas", value: "paid" },
            { label: "Pendente", value: "pending" },
          ]}
        />
        <section id="invoice-container">
          <Header
            setSelectedInvoicing={setSelectedInvoicing}
            invoices={InvoiceData?.invoices || []}
          />
          <section id="invoice-list">
            {InvoiceData?.invoices &&
              InvoiceData.invoices.map((Invoicing) => {
                return (
                  <Invoice
                    selectedInvoicing={selectedInvoicing}
                    setSelectedInvoicing={setSelectedInvoicing}
                    key={Invoicing.invoiceId}
                    invoice={Invoicing}
                    updateInvoices={UpdateInvoice}
                  />
                );
              })}
          </section>
        </section>
        <Pagination
          totalItems={InvoiceData?.totalItems || 1}
          totalPages={InvoiceData?.totalPages || 1}
        />
      </main>
    </>
  );
};

export default InvoicePage;
