import React from "react";
import { StoreType } from "../../../types/Stores";
import { BRL } from "../../../services/Mask";
import { getLanding } from "../../../services/Utils";
import Modal from "../../../components/Modal/Modal";
import DetailsStore from "./DetailsStore";
import { SimpleSearchParams } from "../../../hooks/SearchParams";

const Store = ({
  store,
  possition,
  update,
}: {
  store: StoreType;
  possition: number;
  update: () => void;
}) => {
  let SearchParams = SimpleSearchParams();
  let landing = getLanding(store.ranking);
  return (
    <>
      <Modal modalLabel="info" modalName={store.storeId}>
        <DetailsStore store={store} updateStores={update} />
      </Modal>

      <div
        className="store-classification"
        onClick={() => SearchParams.set("info", store.storeId)}
      >
        <div>{possition}º</div>
        <div className="landing">
          <img src={landing.icon} alt={landing.name} />
          {landing.name}
        </div>
        <div className="store-name">{store.name}</div>
        {localStorage.getItem("isAdmin") === "true" && (
          <div>{BRL(store.invoicing.toFixed(2))}</div>
        )}
        <div></div>
      </div>
    </>
  );
};

export default Store;
