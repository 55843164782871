import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import HeaderComponent from "../../../components/Header/HeaderComponent";
import { ProductType } from "../../../types/Products";
import { UseFetch } from "../../../hooks/useFetch";
import LoadingComponent from "../../../components/Loading/LoadingComponent";
import Product from "./Product";
import AddProduct from "./AddProduct";

const SelectProduct = () => {
  let { id } = useParams();
  let [products, setProducts] = useState<ProductType[]>([]);
  let [selectedProduct, setSelectedProduct] = useState<ProductType>();
  let { data: currentProductsData, update: updateCurrentProducts } = UseFetch<
    ProductType[]
  >("/products", {
    params: { groupId: id },
  });
  let { data: productsData, loading } = UseFetch<ProductType[]>("/products");

  useEffect(() => {
    if (!productsData) return;
    if (!currentProductsData) return;
    let currentProductsId = currentProductsData.map(
      (product) => product.productId
    );
    setProducts(
      productsData.filter(
        (product) => !currentProductsId.includes(product.productId)
      )
    );
  }, [productsData, currentProductsData]);

  if (selectedProduct)
    return (
      <main id="addProduct">
        <HeaderComponent
          title="Adicionar produto"
          lead={selectedProduct.name}
        />
        <LoadingComponent isLoading={loading} />
        <AddProduct
          initialValue={selectedProduct}
          clearProduct={() => {
            updateCurrentProducts();
            setSelectedProduct(undefined);
          }}
        />
      </main>
    );
  return (
    <main id="addProduct">
      <HeaderComponent title="Adicionar produto" />
      <LoadingComponent isLoading={loading} />
      {!products.length && (
        <div className="clear">
          <h3>Todos os produtos já adicionados</h3>
        </div>
      )}
      <section className="products">
        {products.map((product) => (
          <Product
            Product={product}
            handleClick={() => setSelectedProduct(product)}
          />
        ))}
      </section>
    </main>
  );
};

export default SelectProduct;
