import React, { FormEvent, useEffect, useState } from "react";
import { UseFetch } from "../../../hooks/useFetch";
import { StoreType } from "../../../types/Stores";
import HeaderComponent from "../../Header/HeaderComponent";
import { getLanding } from "../../../services/Utils";
import { SimpleSearchParams } from "../../../hooks/SearchParams";
import LoadingComponent from "../../Loading/LoadingComponent";
import InputComponent from "../../Input/InputComponent";

const SelectStore = () => {
  const SearchParams = SimpleSearchParams();

  let [Stores, setStores] = useState<StoreType[]>([]);
  let [filter, setFilter] = useState<string>('');
  const { data, loading } = UseFetch<StoreType[]>("/stores/all");
  useEffect(() => {
    if (data) setStores(data);
  }, [data]);
  return (
    <section className="modal-select-store">
      {/* <header>Select store</header> */}
      <HeaderComponent title="Selecionar Loja" />
      <div className="select-store-options">
        <InputComponent
          placeholder="Filtar"
          type="search"
          id="filter"
          value={filter}
          onChange={(element) => setFilter(element.target.value.toLocaleLowerCase())}
        />
        <div>

          <button
            onClick={() => {
              SearchParams.delete("store");
              SearchParams.delete("modal");
            }}
          >
            Voltar para Administrador
          </button>
        </div>
      </div>
      <div className="stores-container">
        <LoadingComponent isLoading={loading} />
        {Stores.map((store, index) => {
          if (filter && !store.name.toLocaleLowerCase().match(filter)) { return null }
          return <StoreComponent key={index} store={store} />
        }
        )}
      </div>
    </section>
  );
};
export default SelectStore;

const StoreComponent = ({ store }: { store: StoreType }) => {
  const SearchParams = SimpleSearchParams();
  const selectStore = () => {
    SearchParams.set("store", store.storeId);
    SearchParams.delete("modal");
  };
  return (
    <div className="store" onClick={selectStore}>
      <div className="store-rank">
        <img src={getLanding(store.ranking).icon} alt="ranking" />
      </div>
      <div className="store-name">{store.name}</div>
    </div>
  );
};
