import { ApexOptions } from "apexcharts";
import React from "react";
import Chart from "react-apexcharts";

interface ChartType {
  series: {
    name: string;
    data: (number | null)[] | null[];
    color: string;
  }[];
  xaxis?: ApexXAxis;
  dataLabels?: boolean;
  width?: number | string;
  height?: number | string;
}

const AreaChartComponet = ({
  xaxis,
  series,
  dataLabels = false,
  width = "100%",
  height = "100%",
}: ChartType) => {
  const options: ApexOptions = {
    series: series,
    fill: {
      type: "gradient",
    },
    chart: {
      animations: {
        enabled: true,
        dynamicAnimation: { enabled: true },
        animateGradually: { enabled: true },
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: series.map((serie) => serie.color),
    xaxis: { type: "category", ...xaxis },
  };

  return (
    <Chart
      options={options}
      type="area"
      series={options.series}
      width={width}
      height={height}
    />
  );
};

export default AreaChartComponet;
