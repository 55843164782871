import React from "react";
import { DefaultDate, LocaleDate } from "../../../services/Mask";
import { MonthRange, WeekRange } from "../../../services/Utils";
import { useSearchParams } from "react-router-dom";

const Filters = () => {
  const { innerWidth: width } = window;
  let currentDate = new Date();
  let currentMonth = MonthRange({});
  const [SearchParams, setSeatchParams] = useSearchParams({
    startDate: DefaultDate(
      width >= 700
        ? currentMonth.startDate
        : new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDay() - 7
          )
    ),
    endDate: DefaultDate(width >= 700 ? currentMonth.endDate : currentDate),
  });
  const startDate = SearchParams.get("startDate") || "";
  const endDate = SearchParams.get("endDate") || "";
  return (
    <div id="filters">
      <div>
        {LocaleDate(startDate)}-{LocaleDate(endDate)}
      </div>
      <div></div>
    </div>
  );
};

export default Filters;
