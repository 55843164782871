import React, { useState } from "react";
import HeaderComponent from "../../../components/Header/HeaderComponent";
import SelectDate from "./SelectDate";
import SelectStores from "./SelectStores";
import { PostApi } from "../../../services/Api";
import { SimpleSearchParams } from "../../../hooks/SearchParams";

const CreateInvoicing = ({
  updateInvoicing,
}: {
  updateInvoicing: () => void;
}) => {
  let SearchParams = SimpleSearchParams();
  const getStartDayWeek = () => {
    let currentDate = new Date();
    return new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - currentDate.getDay() - 1
    );
  };
  const getEndDayWeek = () => {
    let currentDate = new Date();
    return new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + (5 - currentDate.getDay())
    );
  };
  let [startDate, setStartDate] = useState<Date>(getStartDayWeek());
  let [endDate, setEndDate] = useState<Date>(getEndDayWeek());
  let [dateSelectd, setDateSelected] = useState<boolean>(false);
  let [createLoading, setCreateLoadin] = useState<boolean>(false);
  let [selectedStores, setSelectedStores] = useState<string[]>([]);
  let [message, setMessage] = useState("");

  const createInvoice = async () => {
    if (createLoading) return;
    setCreateLoadin(true);
    let request = await PostApi("invoices", {
      storeIds: selectedStores,
      startDate,
      endDate,
    });
    setCreateLoadin(false);
    if (request.status === 201) {
      setMessage("Faturas criadas");
      updateInvoicing();
      SearchParams.delete("modal");
    } else {
      setMessage("Ocurreu um erro na criação");
    }
  };

  const handleNext = () => {
    setMessage("");
    if (!dateSelectd) {
      setDateSelected(true);
    } else if (dateSelectd) {
      if (!selectedStores.length) {
        setMessage("Você não possue nenhuma loja selecionada");
      } else {
        createInvoice();
      }
    }
  };
  return (
    <main id="createInvoice">
      <HeaderComponent title="Criar Fatura" />
      <section id="message">{message}</section>
      {!dateSelectd && (
        <SelectDate
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
      )}
      {dateSelectd && (
        <SelectStores
          selectedStores={selectedStores}
          setSelectedStores={setSelectedStores}
        />
      )}
      <section id="buttons">
        <button className="second" onClick={()=>SearchParams.delete('modal')}>Cancelar</button>
        <button onClick={handleNext} disabled={createLoading}>
          {!dateSelectd ? "Seguir" : "Gerar Faturas"}
        </button>
      </section>
    </main>
  );
};

export default CreateInvoicing;
