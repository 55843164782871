import React from "react";
import { TrainingVideosType } from "../../../types/Training";
import Modal from "../../../components/Modal/Modal";
import VideoPlayer from "./VideoPlayer";
import { SimpleSearchParams } from "../../../hooks/SearchParams";
import { DeleteApi } from "../../../services/Api";
import { IsAdministrator } from "../../../services/Utils";
import DriveComponent from "../../../components/DriveComponent/DriveComponent";

const VideoComponent = ({
  video,
  index,
  updateVideos,
}: {
  video: TrainingVideosType;
  index: number;
  updateVideos: () => void;
}) => {
  let SearchParams = SimpleSearchParams();
  const handleDelete = async () => {
    let response = await DeleteApi(`/trainingVideos/${video.trainingVideoId}`);

    if (response.status === 204) {
      updateVideos();
      handleClose();
    }
  };
  const handleClose = () => {
    SearchParams.delete("modal");
  };
  return (
    <>
      <Modal modalName={`${video.trainingVideoId}`}>
        {video.externalLink?<DriveComponent videoUrl={video.externalLink}/>: <VideoPlayer video={video} />}
        <div
          style={{
            margin: "0 1rem .5rem 0",
            display: IsAdministrator() ? "flex" : "none",
            justifyContent: "flex-end",
          }}
        >
          <button onClick={handleDelete}>Deletar</button>
        </div>
      </Modal>
      <div
        className="training-video"
        onClick={() => SearchParams.set("modal", `${video.trainingVideoId}`)}
      >
        <section className="video-header">
          <img src={video.imageUrl} alt={video.imageFileKey} />
        </section>
        <section className="video-info">
          <p>{video.title}</p>
        </section>
      </div>
    </>
  );
};

export default VideoComponent;
