import React, { CSSProperties, ChangeEvent, useState } from "react";
import "./styles.css";
import eyeIcon from "../../assets/icons/eye.svg";

type ImputComponent = {
  id: string;
  label?: string;
  type?: "text" | "email" | "password" | "number" | "date" | "tel" | "search";
  required?: boolean;
  minLength?: number;
  onChange?: (element: ChangeEvent<HTMLInputElement>) => void;
  value?: string | number;
  min?: string;
  step?: string;
  maxlength?: string;
  pattern?: string;
  placeholder?: string;
  disabled?: undefined | boolean;
  error?: boolean | string;
  style?: CSSProperties;
  autoComplete?: "on" | "off";
  hidden?: boolean;
};
const InputComponent = ({
  id,
  label,
  type = "text",
  minLength,
  required = false,
  value,
  style,
  error,
  hidden,
  autoComplete,
  onChange,
  ...options
}: ImputComponent) => {
  let [passwordViwe, setpasswordView] = useState(false);
  if (hidden) return null;
  return (
    <div id={id} className="InputComponent">
      {label && <label htmlFor={id}>{label}</label>}
      <div id="content">
        <input
          id={id}
          value={value}
          onChange={onChange}
          className={error ? "error" : ""}
          style={style}
          type={
            type === "password" ? (passwordViwe ? "text" : "password") : type
          }
          minLength={minLength}
          required={required}
          autoComplete={autoComplete}
          {...options}
        />
        {type === "password" && (
          <span onClick={() => setpasswordView(!passwordViwe)}>
            <img src={eyeIcon} alt="eyeIcon" />
          </span>
        )}
      </div>
      {error && error !== true && <span id="error">{error}</span>}
    </div>
  );
};

export default InputComponent;
