import React from "react";
import HeaderComponent from "../../components/Header/HeaderComponent";
import "./styles.css";
const TermsPage = () => {
  return (
    <main id="TermsPage">
      <section className="container">
        <HeaderComponent title="Política de Privacidade da FeatherShoes" />
        <section className="date-validity">
          Data de Vigência: 21 de março de 2024
        </section>
        <div>
          Agradecemos por utilizar os serviços da FeatherShoes. Esta Política de
          Privacidade descreve como coletamos, usamos, compartilhamos e
          protegemos suas informações pessoais quando você utiliza nosso
          aplicativo móvel, website ou outros serviços relacionados
          (coletivamente referidos como "Serviços"). Ao utilizar os Serviços,
          você concorda com a coleta e uso de suas informações pessoais de
          acordo com esta Política de Privacidade.
        </div>
        <div>
          1. Informações que Coletamos: a. Informações que Você nos Fornece:
          Quando você se registra em nossa plataforma, faz uma compra, participa
          de concursos ou interage conosco de qualquer outra forma, podemos
          coletar informações como seu nome, endereço de e-mail, endereço de
          entrega, número de telefone, dados de pagamento e outras informações
          que você nos fornecer. b. Informações de Uso: Coletamos informações
          sobre como você interage com nossos Serviços, incluindo páginas
          acessadas, produtos visualizados, cliques, tempo gasto em cada página
          e outras atividades de uso. c. Informações de Dispositivos: Podemos
          coletar informações sobre o dispositivo que você utiliza para acessar
          nossos Serviços, incluindo o tipo de dispositivo, sistema operacional,
          identificadores exclusivos do dispositivo e informações de rede.
        </div>
        <div>
          2. Uso de Informações: Utilizamos as informações coletadas para os
          seguintes propósitos: a. Para fornecer e manter nossos Serviços; b.
          Para processar suas transações e fornecer suporte ao cliente; c. Para
          personalizar sua experiência e oferecer conteúdo e ofertas relevantes;
          d. Para entender como nossos usuários utilizam nossos Serviços e
          realizar melhorias; e. Para comunicar com você sobre sua conta,
          promoções, novos produtos e outros assuntos relevantes.
        </div>
        <div>
          3. Compartilhamento de Informações: Não compartilhamos suas
          informações pessoais com terceiros, exceto nas seguintes
          circunstâncias: a. Com prestadores de serviços que nos ajudam a operar
          nossos negócios, como processadores de pagamento, provedores de
          serviços de entrega e fornecedores de serviços de TI; b. Quando
          exigido por lei ou para proteger nossos direitos legais; c. Em caso de
          fusão, aquisição ou venda de ativos, onde suas informações podem ser
          transferidas como parte da transação.
        </div>
        <div>
          4. Cookies e Tecnologias Semelhantes: Utilizamos cookies e outras
          tecnologias de rastreamento para melhorar a funcionalidade de nossos
          Serviços, entender como você interage com eles e personalizar sua
          experiência. Você pode controlar o uso de cookies nas configurações do
          seu navegador.
        </div>
        <div>
          5. Segurança das Informações: Implementamos medidas de segurança
          adequadas para proteger suas informações pessoais contra acesso não
          autorizado, uso indevido ou divulgação.
        </div>
        <div>
          6. Seus Direitos de Privacidade: Você tem o direito de acessar,
          corrigir, atualizar ou excluir suas informações pessoais. Se desejar
          exercer esses direitos, entre em contato conosco através dos meios
          fornecidos abaixo.
        </div>
        <div>
          7. Alterações nesta Política de Privacidade: Reservamo-nos o direito
          de atualizar esta Política de Privacidade periodicamente. Quaisquer
          alterações serão publicadas em nosso website e aplicativo móvel.
          Recomendamos que você revise esta Política de Privacidade regularmente
          para se manter informado sobre como protegemos suas informações
          pessoais.
        </div>
        <div>
          8. Contato: Se tiver dúvidas ou preocupações sobre esta Política de
          Privacidade ou sobre nossas práticas de privacidade, entre em contato
          conosco através do seguinte endereço de e-mail: davi@agilease.com.br
          Ao utilizar nossos Serviços, você concorda com esta Política de
          Privacidade e com o processamento de suas informações pessoais
          conforme descrito aqui.
        </div>
        <div>Última atualização: 21 de março de 2024.</div>
      </section>
    </main>
  );
};

export default TermsPage;
