import React, { FormEvent, useState } from "react";
import { DeleteApi } from "../../../services/Api";
import { SimpleSearchParams } from "../../../hooks/SearchParams";
import HeaderComponent from "../../../components/Header/HeaderComponent";

const DeleteSales = ({
  selectedSales,
  updateSales,
  setSelectedSales,
}: {
  selectedSales: string[];
  updateSales: () => void;
  setSelectedSales: (sales: string[]) => void;
}) => {
  const SearchParams = SimpleSearchParams();
  let [confirmInput, setConfirmInput] = useState<string>("");
  let [error, setError] = useState<string>("");
  let [DeleteLoading, setDeleteLoading] = useState<boolean>(false);

  const clearData = () => {
    setConfirmInput("");
    setError("");
  };

  const handleSubmit = async (element: FormEvent<HTMLFormElement>) => {
    element.preventDefault();
    if (DeleteLoading) return;
    setDeleteLoading(true);
    if (confirmInput !== "CONFIRMO") return;

    DeleteApi("/sales", {
      saleIds: selectedSales,
    })
      .then(() => {
        updateSales();
        setSelectedSales([]);
        SearchParams.delete("modal");
      })
      .catch(() => {
        setError("Ocorreu um erro ao excluir as vendas");
      })
      .finally(() => {
        setDeleteLoading(false);
        clearData();
      });
  };
  return (
    <section id="DeleteSales">
      <HeaderComponent title="Deletar Vendas" />
      <div className="content">
        <p>
          Para exclusão de uma ou mais vendas, favor escrever abaixo a palavra
          CONFIRMO
        </p>
        {error && <p>{error}</p>}
        {DeleteLoading && <p>Carregando...</p>}
        <form onSubmit={handleSubmit}>
          <input
            id="deleteSalesConfirm"
            value={confirmInput}
            onChange={(e) => setConfirmInput(e.target.value.toUpperCase())}
            placeholder="CONFIRMO"
            autoComplete="off"
          />
          <div id="buttons">
            <button disabled={confirmInput !== "CONFIRMO"} type="submit">
              Excluir
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default DeleteSales;
