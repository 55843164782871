import { URLSearchParamsInit, useSearchParams } from "react-router-dom";

interface SetParams {
  key: string | number;
  value: string | number;
}

export const SimpleSearchParams = (defaultValue?: URLSearchParamsInit) => {
  const [RouterSearchParams, setRouterSearchParams] =
    useSearchParams(defaultValue);
  class SearchParams {
    set = (key: string | number, value: string | number, now = true): void => {
      RouterSearchParams.set(`${key}`, `${value}`);
      if (now) setRouterSearchParams(RouterSearchParams);
    };

    get = (key: string) => {
      let value = RouterSearchParams.get(key);
      return Number(value) || value;
    };
    getAll = () => {
      let value = RouterSearchParams.getAll("store");
      return Number(value) || value;
    };
    delete = (key: string) => {
      RouterSearchParams.delete(key);
      setRouterSearchParams(RouterSearchParams);
    };

    setParms = (params: SetParams[]): void => {
      params.map(({ key, value }) => this.set(key, value, false));
      setRouterSearchParams(RouterSearchParams);
    };
  }
  return new SearchParams();
};
