import React, { useEffect } from "react";
import "./styles.css";
import { UseFetch } from "../../hooks/useFetch";
import Stores from "./components/Stores";
import { StoreType } from "../../types/Stores";
import Pagination from "../../components/Pagination/PaginationComponent";
import Modal from "../../components/Modal/Modal";
import CreateStore from "./components/CreateStore";
import HeaderComponent from "../../components/Header/HeaderComponent";
import FilterComponent from "../../components/Filter/FilterComponent";
import { SimpleSearchParams } from "../../hooks/SearchParams";
const Classification = () => {
  const SearchParams = SimpleSearchParams({
    page: "1",
    perPage: "10",
  });

  let page = SearchParams.get("page");
  let perPage = SearchParams.get("perPage");

  const {
    data: StoresList,
    loading: StoresListLoading,
    update: updateStores,
  } = UseFetch<{
    stores: StoreType[];
    totalPages: number;
    totalItems: number;
  }>("/stores", { params: { orderAttribute: "invoicing", page, perPage } });
  useEffect(updateStores, [page, perPage]);
  return (
    <>
      <Modal modalName="CreateStore">
        <CreateStore />
      </Modal>
      <main id="Classification">
        <HeaderComponent title="Classificação" />

        <FilterComponent
          // viewName="storesView"
          perPage
          buttons={[
            {
              forAdmin: true,
              label: "+ Adicionar Loja",
              action: () => SearchParams.set("modal", "CreateStore"),
            },
          ]}
        />
        <main>
          <Stores
            update={updateStores}
            stores={StoresList?.stores}
            isLoading={StoresListLoading}
          />
        </main>
        <footer>
          <Pagination
            totalItems={StoresList?.totalItems || 0}
            totalPages={StoresList?.totalPages || 1}
          />
        </footer>
      </main>
    </>
  );
};

export default Classification;
