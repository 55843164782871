import React, { useEffect } from "react";
import { getLanding } from "../../services/Utils";
import { BRL } from "../../services/Mask";
import "./style.css";
import { UseFetch } from "../../hooks/useFetch";
import { StoreType } from "../../types/Stores";
import { SimpleSearchParams } from "../../hooks/SearchParams";

const MetaComponet = () => {
  let SearchParams=SimpleSearchParams()
  let selectedStore=SearchParams.get(`store`)
  const { data,update } = UseFetch<StoreType>("/store",{params:{
    storeId:selectedStore
  }});
  const landing = getLanding(data?.ranking || "iron");
  const invoicing = data?.invoicing || 0;
  const metaPercent = (invoicing * 100) / landing.finalValue;

  useEffect(()=>{update()},[selectedStore])
  return (
    <div className="meta">
      <div className="meta-info">
        <div className="meta-info-ranking">
          <img src={landing.icon} alt="" />
          <p>{landing.name}</p>
        </div>
        <p>
          {invoicing ? BRL(Math.round(invoicing * 100)) : "R$ 0,00"} /{" "}
          {BRL(landing.finalValue * 100).replace(",00", "")}
        </p>
      </div>
      <div className="meta-progressbar-container">
        <div className="meta-progressbar">
          <span style={{ width: `${metaPercent}%` }} />
        </div>
      </div>
    </div>
  );
};

export default MetaComponet;
