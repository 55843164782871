import React from "react";
import "./styles.css";
const Separator = ({ title = "" }) => {
  return (
    <div className="separator">
      <div className="line"></div>
      <span>{title}</span>
    </div>
  );
};

export default Separator;
