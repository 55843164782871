import React, { useState } from "react";
import InputComponent from "../../../components/Input/InputComponent";
import HeaderComponent from "../../../components/Header/HeaderComponent";
import Permissions from "./Permissions";
import { SimpleSearchParams } from "../../../hooks/SearchParams";
import { PostApi } from "../../../services/Api";
import LoadingComponent from "../../../components/Loading/LoadingComponent";

const CreateGroup = () => {
  let searchParams = SimpleSearchParams();
  let [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);
  let [name, setName] = useState<string>("");
  let [description, setDescription] = useState<string>("");
  let [hasCommission, setHasCommission] = useState(false);
  let [useDefaultProducts, setUseDefaultProducts] = useState(true);
  let [errorMessage, setErrorMessage] = useState<string>("");
  let [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    if (
      !selectedPermissions.length ||
      !name.length ||
      !description.length ||
      loading
    )
      return;
    setErrorMessage("");
    setLoading(true);
    let res = await PostApi("/groups", {
      name,
      description,
      permissions: selectedPermissions,
      hasCommission,
      useDefaultProducts,
    });
    setLoading(false);
    if (res.status === 200 || res.status === 201) {
      searchParams.delete("modal");
    } else {
      setErrorMessage("Erro ao criar grupo");
    }
  };
  return (
    <main id="createGroup">
      <HeaderComponent title="Criar Grupo" />
      <LoadingComponent isLoading={loading} />
      {errorMessage}
      <div className="form">
        <InputComponent
          label="Nome:"
          id="name"
          required
          value={name}
          onChange={(e) => setName(e.currentTarget.value)}
        />
        <InputComponent
          label="Descrição:"
          id="description"
          required
          value={description}
          onChange={(e) => setDescription(e.currentTarget.value)}
        />
        <div id="hasCommission">
          O grupo é comissionado?
          <button onClick={() => setHasCommission(!hasCommission)}>
            {hasCommission ? "Sim" : "Não"}
          </button>
        </div>
        <div id="hasCommission">
          O grupo usa o catálogo padrão?
          <button onClick={() => setUseDefaultProducts(!useDefaultProducts)}>
            {useDefaultProducts ? "Sim" : "Não"}
          </button>
        </div>
      </div>
      <Permissions
        setSelectedPermissions={setSelectedPermissions}
        selectedPermissions={selectedPermissions}
      />
      <section className="buttons">
        <button className="second" onClick={() => searchParams.delete("modal")}>
          Cancelar
        </button>
        <button
          disabled={
            !selectedPermissions.length ||
            !name.length ||
            !description.length ||
            loading
          }
          onClick={onSubmit}
        >
          Criar
        </button>
      </section>
    </main>
  );
};

export default CreateGroup;
