import React from "react";

const LoadingComponent = ({ isLoading = true }) => {
  if (!isLoading) return null;
  return (
    <div id="loadingComponent">
      <h3 id="clean">Carregando...</h3>
    </div>
  );
};

export default LoadingComponent;
