import React, { FormEvent, useState } from "react";
import InputComponent from "../../../../components/Input/InputComponent";
import HeaderComponent from "../../../../components/Header/HeaderComponent";
import DropZone from "../../../../components/DropZone/DropZone";
import DriveComponent from "../../../../components/DriveComponent/DriveComponent";
import axios from "axios";
import { Api } from "../../../../services/Api";
import { SimpleSearchParams } from "../../../../hooks/SearchParams";
interface videoType {
  title: string;
  driveId: string | null;
  tumbnail?: File;
}
const CreateVideo = () => {
  let [video, setVideo] = useState<videoType>({ title: "", driveId: null });
  let [driveIdValid, setDriveIdValid] = useState(false);
  let [loading, setLoading] = useState(false);
  const SearchParams=SimpleSearchParams()
  const handleSelectImage = (file: File) => {
    setVideo({ ...video, tumbnail: file });
  };
  const testDriveId = async (currentDriveId: string) => {
    let response = await axios
      .get(`https://drive.google.com/file/d/${currentDriveId}/preview`)
      .catch((e) => e);
    if (response.status === 200) {
      setVideo({ ...video, driveId: currentDriveId });
      setDriveIdValid(true);
    } else {
      setDriveIdValid(false);
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!video.driveId || !video.title || !video.tumbnail)
      return console.error("parametros");

    let formData = new FormData();
    formData.append("tumbnail", video.tumbnail);
    formData.append("title", video.title);
    formData.append(
      "externalLink",
      `https://drive.google.com/file/d/${video.driveId}/preview`
    );
    setLoading(true);
    let response = await Api.post("/trainingVideos/external", formData).finally(()=>setLoading(false))
    if(response.status===201){
      SearchParams.delete('modal')
    }
  };
  return (
    <section id="createVideo">
      <HeaderComponent title="Criar video" />
      <form onSubmit={handleSubmit}>
        <div className="container">
          <div className="videoPreview">
            {driveIdValid && video.driveId ? (
              <DriveComponent videoKey={video.driveId} />
            ) : (
              <div className="invalidKey">"id invalido"</div>
            )}
          </div>
          <InputComponent
            id="driveId"
            label="Identificador do drive"
            value={video.driveId || ""}
            onChange={(e) => {
              setVideo({ ...video, driveId: e.target.value });
              testDriveId(e.target.value);
            }}
            required
          />
        </div>
        <div className="container">
          <DropZone
            accept="image/png,image/jpeg"
            onChange={handleSelectImage}
            message="Selecione a Tumbnail."
            imagePreview={true}
            required
          />
          <InputComponent
            id="title"
            label="Título"
            value={video.title}
            onChange={(e) => {
              setVideo({ ...video, title: e.target.value });
            }}
            required
          />
        </div>
      </form>
      <footer>
        <button>Cancelar</button>
        <button
          onClick={handleSubmit}
          disabled={
            !video.title ||
            !video.driveId ||
            !video.tumbnail ||
            !driveIdValid ||
            loading
          }
        >
          {!loading ? "Enviar" : "Enviando"}
        </button>
      </footer>
    </section>
  );
};

export default CreateVideo;
