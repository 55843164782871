import React, { ChangeEvent, useEffect, useState } from "react";
import HeaderComponent from "../../../components/Header/HeaderComponent";
import InputComponent from "../../../components/Input/InputComponent";
import { SimpleSearchParams } from "../../../hooks/SearchParams";
import { ProductType } from "../../../types/Products";
import { useParams } from "react-router-dom";
import { PutApi } from "../../../services/Api";
import Modal from "../../../components/Modal/Modal";
import DeleteProduct from "./DeleteProduct";
import { GroupType } from "../../../types/Groups";
import { UseFetch } from "../../../hooks/useFetch";
interface creationType {
  name: string;
  manufacturingValue: number;
  acquisitionValue: number;
  standardAmount: number;
  originalManufacturingValue: number;
}
const EditProduct = ({ product_default }: { product_default: ProductType }) => {
  let { id } = useParams();
  let { data: group } = UseFetch<GroupType>(`/groups/${id}`);
  let [loading, setLoading] = useState(false);
  let [isShopee, setIsShopee] = useState(false);
  let [product, setProduct] = useState<creationType>({
    acquisitionValue: 0,
    manufacturingValue: 0,
    name: "",
    standardAmount: 0,
    originalManufacturingValue: 0,
  });
  let [edited, setEdited] = useState<boolean>(false);
  let [errorMessage, setErrorMessage] = useState<string>();
  let SearchParams = SimpleSearchParams();
  const handleChange = (element: ChangeEvent<HTMLInputElement>) => {
    setEdited(true);
    let target = element.currentTarget;
    setProduct({ ...product, [target.id]: target.value });
  };
  useEffect(() => {
    let data = product_default;
    setProduct({
      acquisitionValue: data.acquisitionValue,
      standardAmount: data.standardAmount,
      name: data.name,
      manufacturingValue: data.manufacturingValue,
      originalManufacturingValue: data.originalManufacturingValue || 0,
    });
  }, []);

  const handleSubmit = async () => {
    if (
      !product.acquisitionValue ||
      !product.manufacturingValue ||
      !product.standardAmount
    )
      return setErrorMessage("Todos os campos são obrigatórios");

    setLoading(true);
    console.log({
      groupId: id,
      acquisitionValue: Number(product.acquisitionValue) * 100,
      manufacturingValue: Number(product.manufacturingValue) * 100,
      standardAmount: Number(product.standardAmount) * 100,
      originalManufacturingValue:
        Number(product.originalManufacturingValue) * 100,
    });

    let response = await PutApi(
      `groups/products/${product_default.productId}`,
      {
        groupId: id,
        acquisitionValue: Number(product.acquisitionValue) * 100,
        manufacturingValue: Number(product.manufacturingValue) * 100,
        standardAmount: Number(product.standardAmount) * 100,
        originalManufacturingValue:
          Number(product.originalManufacturingValue) * 100,
      }
    )
      .then((res) => res)
      .catch((err) => {
        setErrorMessage("Ocorreu um erro na edição do produto");
        console.error(err);
        return err;
      });
    setLoading(false);
    if (response.status === 204) {
      SearchParams.delete("editProduct");
    } else {
      setErrorMessage("Ocorreu um erro na edição do produto");
    }
  };
  const storeProfit = () => {
    let totalAmont = product.standardAmount;
    let taxedAmont = totalAmont * 0.8 - 4;
    let cost = product.acquisitionValue;
    if (isShopee) return taxedAmont - cost;
    return totalAmont - cost;
  };
  const factoryProfit = () => {
    if (!group?.hasCommission)
      return product.manufacturingValue - product.originalManufacturingValue;
    return product.acquisitionValue - product.originalManufacturingValue;
  };
  return (
    <>
      <Modal modalLabel="delete" modalName={product_default.productId}>
        <DeleteProduct productId={product_default.productId} />
      </Modal>
      <main id="updateGroupProduct">
        <HeaderComponent
          title="Editar Produto"
          lead="Preencha as informações abaixo para editar um produto"
        />
        <section>
          <div>
            {errorMessage && <h2 className="error">{errorMessage}</h2>}
            <InputComponent
              id="name"
              label="Nome:"
              type="text"
              value={product.name}
              disabled
              error={!product.name}
            />
            <div
              className="manufacturing"
              style={{
                gridTemplateColumns: group?.hasCommission ? "1fr" : "1fr 1fr",
              }}
            >
              <InputComponent
                id="originalManufacturingValue"
                label="Custo de Produção:"
                type="number"
                step="0.01"
                min="0"
                onChange={handleChange}
                value={product.originalManufacturingValue}
              />
              <InputComponent
                id="manufacturingValue"
                label="Valor de venda fábrica:"
                type="number"
                step="0.01"
                min="0.01"
                onChange={handleChange}
                value={product.manufacturingValue}
                error={!product.manufacturingValue}
                hidden={group?.hasCommission}
              />
            </div>
            <div className="values">
              <InputComponent
                id="acquisitionValue"
                label="Valor de aquisição:"
                type="number"
                step="0.01"
                min="0.01"
                onChange={handleChange}
                required
                value={product.acquisitionValue}
                error={!product.acquisitionValue}
              />

              <span>
                <p>Lucro da fabrica: </p>
                <p>
                  R$
                  {factoryProfit()}
                </p>
              </span>
            </div>
            <div className="values" id="shoope">
              <InputComponent
                id="standardAmount"
                label="Valor sugerido:"
                type="number"
                step="0.01"
                min="0.01"
                value={product.standardAmount}
                onChange={handleChange}
                required
                error={!product.standardAmount}
              />
              <div id="isShoope">
                <p>Venda na shoope?</p>
                <button onClick={() => setIsShopee(!isShopee)}>
                  {isShopee ? "Sim" : "Não"}
                </button>
              </div>
              <span>
                <p style={{ marginLeft: "1rem" }}>Lucro da loja: </p>
                <p>
                  R$
                  {storeProfit().toFixed(2)}
                </p>
              </span>
            </div>
          </div>
          <div id="dropzone-container"></div>
        </section>
        <footer>
          <button
            onClick={() =>
              SearchParams.set("delete", product_default.productId)
            }
          >
            Deletar
          </button>
          <button
            className="second"
            onClick={() => SearchParams.delete("editProduct")}
          >
            Cancelar
          </button>
          <button
            disabled={
              !edited ||
              !product.acquisitionValue ||
              !product.manufacturingValue ||
              !product.name ||
              !product.standardAmount ||
              loading
            }
            onClick={handleSubmit}
          >
            {loading ? "Carregando" : "Editar"}
          </button>
        </footer>
      </main>
    </>
  );
};

export default EditProduct;
