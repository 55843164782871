import React from "react";
import InputComponent from "../../components/Input/InputComponent";

const SetPasswordPage = () => {
  return (
    <main id="SetPassWord">
      <section id="container">
        <header>logo</header>
        <main>
          <form action="">
            <InputComponent label="Nova Senha" id="password" type="password" />
            <InputComponent label="Nova Senha" id="password" type="password" />
          </form>
        </main>
      </section>
    </main>
  );
};

export default SetPasswordPage;
