import React, { ChangeEvent, useEffect, useState } from "react";
import HeaderComponent from "../../../components/Header/HeaderComponent";
import InputComponent from "../../../components/Input/InputComponent";
import { UseFetch } from "../../../hooks/useFetch";
import { GroupType } from "../../../types/Groups";
import { StoreType } from "../../../types/Stores";
import { PutApi } from "../../../services/Api";
import { PhoneNumber } from "../../../services/Mask";
import { SimpleSearchParams } from "../../../hooks/SearchParams";

const EditStore = ({ store }: { store: StoreType }) => {
  let SearchParams = SimpleSearchParams();
  let [errorMessage, setErrorMessage] = useState("");
  let { data: groups } = UseFetch<GroupType[]>("/groups");
  let [userData, setUserData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    storeName: "",
    groupId: "",
  });
  useEffect(() => {
    if (!store.user || !store.user.group) return;
    setUserData({
      storeName: store.name,
      email: store.user.email,
      phoneNumber: store.user.phoneNumber
        ? store.user.phoneNumber.replace("+55", "")
        : "",
      name: store.user.name,
      groupId: store.user.group.groupId,
    });
  }, []);
  const handleUpdate = async () => {
    if (!store.user) return;
    let response = await PutApi(`users/${store.user.userId}`, {
      ...userData,
      phoneNumber: "+55" + userData.phoneNumber.replace(/\D/g, ""),
    });

    if (response.status === 201) {
      return SearchParams.delete("modal");
    } else {
      setErrorMessage("Ocorreu um erro ao atualizar a loja");
    }
  };
  const handleChange = (element: ChangeEvent<HTMLInputElement>) => {
    setUserData({
      ...userData,
      [element.target.id]: element.target.value,
    });
  };
  return (
    <section id="editStore">
      <HeaderComponent title="Editar Loja" />
      <div id="container">
        <div>
          <InputComponent
            label="Nome:"
            onChange={handleChange}
            value={userData.storeName}
            id="storeName"
          />
        </div>
        <div>
          <InputComponent
            label="Número de Telefone:"
            onChange={handleChange}
            value={PhoneNumber(userData.phoneNumber)}
            id="phoneNumber"
            maxlength="16"
          />
        </div>
        <div>
          <InputComponent
            label="Email:"
            onChange={handleChange}
            value={userData.email}
            id="email"
          />
        </div>
        <div>
          <InputComponent
            label="Nome do Responsavel:"
            onChange={handleChange}
            value={userData.name}
            id="name"
          />
        </div>
        <div className="group">
          <label htmlFor="group">Grupo:</label>
          <select
            name="group"
            id="group"
            value={userData.groupId}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
              let { target } = e;
              setUserData({ ...userData, groupId: target.value });
            }}
          >
            <option disabled>Grupo</option>
            {groups &&
              groups.map((group) => (
                <option value={group.groupId}>{group.name}</option>
              ))}
          </select>
        </div>
      </div>
      <div className="buttons">
        <p className="error">{errorMessage}</p>
        <button className="third" onClick={() => SearchParams.delete("modal")}>
          Cancelar
        </button>
        <button onClick={handleUpdate} disabled={!store.user}>
          Atualizar
        </button>
      </div>
    </section>
  );
};

export default EditStore;
