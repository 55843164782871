import React from "react";
import CalendarComponent from "../../../components/Calendar/CalendarComponent";
interface selectDateType {
  startDate: Date;
  endDate: Date;
  setStartDate: (value: Date) => void;
  setEndDate: (value: Date) => void;
}
const SelectDate = ({
  startDate,
  endDate,
  setEndDate,
  setStartDate,
}: selectDateType) => {
  return (
    <main id="selectDate">
      <CalendarComponent initialDate={startDate} onChange={setStartDate} />
      <CalendarComponent initialDate={endDate} onChange={setEndDate} />
    </main>
  );
};

export default SelectDate;
