import React, { useEffect, useState } from "react";
import { StoreType } from "../../../types/Stores";
import Store from "./Store";
import { SimpleSearchParams } from "../../../hooks/SearchParams";

const Stores = ({
  stores,
  isLoading,
  update,
}: {
  update: () => void;
  stores?: StoreType[] | null;
  isLoading: boolean;
}) => {
  let SearchParams = SimpleSearchParams({ perPage: "10", page: "1" });
  let [StoresList, setStoreList] = useState<StoreType[]>([]);
  let viewStyle = "list";
  let page = Number(SearchParams.get("page"));
  let perPage = Number(SearchParams.get("perPage"));
  useEffect(() => {
    if (stores) {
      setStoreList(stores);
    }
  }, [stores, isLoading]);

  return (
    <section id="StoresList">
      <div className="stores-classification-header">
        <div>Posição</div>
        <div className="landing">Ranking</div>
        <div className="store-name">Nome</div>
        {localStorage.getItem("isAdmin") === "true" && <div>Lucro</div>}
      </div>
      <div id="container" className={viewStyle}>
        {StoresList.map((store, index) => (
          <Store
            update={update}
            store={store}
            key={index}
            possition={perPage * (page - 1) + index + 1}
          />
        ))}
      </div>
    </section>
  );
};

export default Stores;
