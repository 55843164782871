import React from "react";
import HeaderComponent from "../../components/Header/HeaderComponent";
import FilterComponent from "../../components/Filter/FilterComponent";
import Modal from "../../components/Modal/Modal";
import { UseFetch } from "../../hooks/useFetch";
import { GroupType } from "../../types/Groups";
import GroupCard from "./components/GroupCard";
import "./styles.css";
import { SimpleSearchParams } from "../../hooks/SearchParams";
import CreateGroup from "./components/CreateGroup";
const GroupPage = () => {
  let searchParams = SimpleSearchParams();
  let { data: groups, update } = UseFetch<GroupType[]>("/groups");

  return (
    <>
      <Modal modalName="createGroup" onClose={update}>
        <CreateGroup />
      </Modal>
      <main id="GroupPage">
        <HeaderComponent title="Grupos" />
        <FilterComponent
          buttons={[
            {
              label: "+ Criar Grupo",
              action: () => searchParams.set("modal", "createGroup"),
              forAdmin: true,
            },
          ]}
        />
        <section className="container">
          {groups &&
            groups.map((group) => (
              <GroupCard group={group} updateGroups={update} />
            ))}
        </section>
      </main>
    </>
  );
};

export default GroupPage;
