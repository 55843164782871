import React, { useEffect, useState } from "react";
import Header from "./components/Header";
import WeekComponent from "./components/WeekComponent";
import "./styles.css";
import { dayType } from "../../types/Calendar";
import { DefaultDate } from "../../services/Mask";

interface CalendarComponentType {
  initialDate?: Date;
  onChange: (value: Date) => void;
}

const CalendarComponent = ({
  initialDate,
  onChange,
}: CalendarComponentType) => {
  const getDayInfo = (date: Date) => {
    return {
      day: date.getDate(),
      month: date.getMonth(),
      year: date.getFullYear(),
    };
  };
  let [weeks, setWeeks] = useState<dayType[][]>([]);
  let [selectedMonth, setSelectedMonth] = useState<number>(
    new Date().getMonth()
  );
  let [selectedDay, setselectedDay] = useState<dayType>(
    getDayInfo(initialDate || new Date())
  );

  const getWeeks = ({ year, month }: { year: number; month: number }) => {
    let selectedMonth = new Date(year, month, 1);
    let nowDate = new Date();
    let weekPossition = selectedMonth.getDay();
    console.clear();

    let currentDay = new Date(
      year,
      month,
      selectedMonth.getDate() - weekPossition
    );

    let monthWeeks: dayType[][] = [];
    for (let monthLength = false; monthLength === false; ) {
      let week: dayType[] = [];
      while (week.length <= 6) {
        week.push({
          ...getDayInfo(currentDay),
          now: DefaultDate(currentDay) === DefaultDate(nowDate),
          currentMonth: currentDay.getMonth() === selectedMonth.getMonth(),
          selected:
            DefaultDate(
              new Date(selectedDay.year, selectedDay.month, selectedDay.day)
            ) === DefaultDate(currentDay),
        });
        currentDay = new Date(
          currentDay.getFullYear(),
          currentDay.getMonth(),
          currentDay.getDate() + 1
        );
        if (monthWeeks.length === 5) monthLength = true;
      }
      monthWeeks.push(week);
    }
    console.log("month", monthWeeks);
    setWeeks(monthWeeks);
    console.log(currentDay);
  };
  useEffect(() => {
    let date = new Date();

    setWeeks([]);
    getWeeks({ year: date.getFullYear(), month: selectedMonth });
  }, [selectedMonth, selectedDay]);
  useEffect(() => {
    onChange(new Date(selectedDay.year, selectedDay.month, selectedDay.day));
    if (initialDate) {
      setSelectedMonth(initialDate.getMonth());
    }
  }, [selectedDay]);
  return (
    <section id="calendarComponent">
      <Header
        selectedMonth={selectedMonth}
        setSelectedMonth={setSelectedMonth}
      />
      <section id="month">
        {weeks.map((week, i) => (
          <WeekComponent key={i} week={week} setselectedDay={setselectedDay} />
        ))}
        <br />
      </section>
    </section>
  );
};

export default CalendarComponent;

// for (let weeksLength = 0; weeksLength <= 4; weeksLength++) {
//   let currentWeek: dayType[] = [];
//   for (let index = 0; currentWeek.length < 7; index++) {
//     let { day, month, year } = getDayInfo(currentDay);

//     currentWeek.push({ day, month, year });
//     currentDay = new Date(year, month, day + 1);
//   }

//   setWeeks([...weeks, currentWeek]);
// }
